import { Fragment } from "react";
import LoadingRing from "src/components/Loaders/LoadingRing/LoadingRing";
import useLocalize from "src/services/localize/useLocalize";
import { useTheme } from "src/ui-kit/theme";
import { AccountPageInitParams } from "../../types";
import AccountAddresses from "../AccountAddresses/AccountAddresses";
import AccountEmptyDataPlaceholder from "../AccountEmptyDataPlaceholder/AccountEmptyDataPlaceholder";
import AddressesDeleteModal from "../AddressesDeleteModal/AddressesDeleteModal";
import AddressPlanEditModal from "../AddressPlanEditModal/AddressPlanEditModal";
import ChainsSelect from "../ChainsSelect/ChainsSelect";
import useChainsSelect from "../ChainsSelect/useChainsSelect";
import useAccountAddressesLogic from "./useAccountAddressesLogic";

const AddressesPageContent = (props: AccountPageInitParams) => {
  const { chains, lastVisitChainId, profileId } = props;
  const theme = useTheme();
  const {
    activeChain,
    chainsOptions,
    handleOptionChange,
  } = useChainsSelect({ chains, lastVisitChainId });

  const {
    deliveryPresets,
    deliveryPresetsAreFetching,
    editAddressModalData,
    deleteAddressModalData,
    deleteAddressClick,
    editAddressClick,
    closeEditModal,
    closeDeleteModal,
  } = useAccountAddressesLogic(activeChain.instance, profileId);

  const addressesListIsVisible = !deliveryPresetsAreFetching && (deliveryPresets.length > 0);
  const noSavedAddressesIsVisible = !deliveryPresetsAreFetching && (deliveryPresets.length === 0);
  const localize = useLocalize();

  return (
    <Fragment>
      <ChainsSelect
        options={chainsOptions}
        activeValue={activeChain}
        onChange={handleOptionChange}
      />

      {
        deliveryPresetsAreFetching && <LoadingRing color={theme.base.color.primary} size="60" />
      }

      {
        addressesListIsVisible &&
        <AccountAddresses
          addresses={deliveryPresets}
          onDeleteAddressClick={deleteAddressClick}
          onEditAddressClick={editAddressClick}
        />
      }

      {deleteAddressModalData && (
        <AddressesDeleteModal
          address={deleteAddressModalData}
          onClose={closeDeleteModal}
          profileId={profileId}
          chainId={activeChain.instance.id}
        />
      )}

      {editAddressModalData && (
        <AddressPlanEditModal
          delivery={editAddressModalData}
          onClose={closeEditModal}
          profileId={profileId}
          chainId={activeChain.instance.id}
        />
      )}

      {
        noSavedAddressesIsVisible && <AccountEmptyDataPlaceholder text={localize('no_saved_addresses')} />
      }
    </Fragment>
  );
};

export default AddressesPageContent;
