import { useMemo } from 'react';
import LoadingRing from 'src/components/Loaders/LoadingRing/LoadingRing';
import { HORECA_B2B_LINK } from 'src/data/constants';
import { getErrorText, useBusinessTypes } from 'src/modules/authorizationModal';
import useLang from 'src/services/language/useLang';
import useLocalize from 'src/services/localize/useLocalize';
import Button from 'src/ui-kit/Button';
import { useTheme } from 'src/ui-kit/theme';
import ErrorMessage from 'src/UIKitOld/ErrorMessage/ErrorMessage';
import FormField from 'src/UIKitOld/FormField/FormField';
import SelectStyled from 'src/UIKitOld/SelectStyled/SelectStyled';
import getDataStatus from 'src/utils/getDataStatus';
import useHorecaRegistration, { RegistrationBusinessType } from '../../hooks/useHorecaRegistration/useHorecaRegistration';
import { HorecaRegistrationStyled } from './HorecaRegistration.styles';

const HorecaRegistration = () => {
  const language = useLang();
  const localize = useLocalize();
  const theme = useTheme();
  const { businessTypes } = useBusinessTypes(language);

  const {
    fields,
    handleFieldChange,
    handleRegistration,
    requestErrors,
    isLoading,
  } = useHorecaRegistration();

  const businessTypeOptionList: RegistrationBusinessType[] = useMemo(() => {
    return businessTypes.map(b => ({
      label: b.name,
      value: b.id,
    }));
  }, [businessTypes]);

  const handleSelectionChange = (option: RegistrationBusinessType | null) => {
    if (option) {
      handleFieldChange({ businessType: option.value });
    }
  };

  const selectedBusinessType = businessTypeOptionList.find(b => b.value === fields.businessType) || null;

  const canContinueClick =  fields.companyName.length > 0 && fields.businessType.length > 0;

  return (
    <HorecaRegistrationStyled
      className="HorecaRegistration"
      data-testid="horeca-registration"
      theme={theme}
    >
      {isLoading && (
        <div
          className='HorecaRegistration__loader'
          data-testid='LoadingRing'
        >
          <LoadingRing color={theme.base.color.primary} size='53' />
        </div>
      )}

      <a
        href={HORECA_B2B_LINK}
        target="_blank"
        rel="noreferrer"
        className='HorecaRegistration__link'
        data-marker="Company advantages"
      >
        {localize('more_about_the_benefits')}
      </a>

      <div className="HorecaRegistration__form">
        <div className='HorecaRegistration__input'>
          <FormField
            id="company"
            onChange={(e) => handleFieldChange({ companyName: e.target.value })}
            value={fields.companyName}
            label={localize('company_name')}
            inputProps={{
              dataMarker: 'Company name',
            }}
          />
        </div>

        <label
          className='HorecaRegistration__label'
          htmlFor='company-type'
        >
          {localize('legal_form')}
        </label>
        <SelectStyled
          tabIndex='1'
          inputId='company-type'
          options={businessTypeOptionList}
          value={selectedBusinessType}
          menuPosition='absolute'
          onChange={handleSelectionChange}
          dataMarker='Company type'
          singleValueFocusedColor={theme.base.color.gray500}
          placeholder={null}
        />

        {requestErrors.length > 0 && (
          <div className="HorecaRegistration__errors">
            <ErrorMessage dataTestId="common-error">
              {getErrorText(requestErrors, localize)}
            </ErrorMessage>
          </div>
        )}

        <div className='HorecaRegistration__button'>
          <Button
            disabled={!canContinueClick || isLoading}
            onClick={handleRegistration}
            type='button'
            dataMarker='Continue'
            data-status={getDataStatus(canContinueClick)}
            variant="primary"
          >
            <span>{localize('general.registration-2')}</span>
          </Button>
        </div>
      </div>
    </HorecaRegistrationStyled>
  );
};

export default HorecaRegistration;
