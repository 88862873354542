import styled from '@emotion/styled';
import { getAddressInfo } from 'src/modules/account';
import useLocalize from 'src/services/localize/useLocalize';
import { ServerCartDelivery } from "src/services/userDelivery/types";
import { useTheme } from "src/ui-kit/theme";
import { StyledThemeProps } from "src/ui-kit/theme/types";
import { Typography } from 'src/ui-kit/Typography';
import Radio from 'src/UIKitOld/Radio/Radio';
import AddressDetailsText from './AddressDetailsText';

export const StyledAddressPresetItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps & { isChecked?: boolean }>(({ theme, isChecked }) => `

    & .AddressPresetItem__inner {
      border: 1px solid ${isChecked ? theme.base.color.primary : theme.base.color.gray300};
      padding: 12px;
      cursor: pointer;
    }

    &:hover .AddressPresetItem__inner {
      border-color: ${theme.base.color.primary};
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
`);

interface AddressPresetItemProps {
  preset: ServerCartDelivery;
  onClick: () => void;
  isChecked: boolean;
  hasError?: boolean;
  errorMessage?: string;
}

const AddressPresetItem = (props: AddressPresetItemProps) => {
  const { preset, isChecked, onClick, hasError, errorMessage } = props;
  const theme = useTheme();
  const localize = useLocalize();

  const addressInfo = getAddressInfo(preset, localize);

  return (
    <StyledAddressPresetItem
      className="AddressPresetItem"
      theme={theme}
      isChecked={isChecked}
    >
      <div className='AddressPresetItem__inner'>
        <Radio
          checked={isChecked}
          name="delivery"
          value={preset.hash}
          onClick={onClick}
          fullWidth
        >
          <AddressDetailsText
            deliveryType={preset.type}
            address={addressInfo.address}
            deliveryExtra={addressInfo.extra}
          />
        </Radio>
      </div>
      {hasError &&
        <Typography
          dataMarker="Address not available"
          variant='text6'
          color='error'
          sbs={{ mt: '4px' }}
        >
          {errorMessage}
        </Typography>
      }
    </StyledAddressPresetItem>
  );
};

export default AddressPresetItem;
