import { Fragment } from 'react';
import { DeliveryType } from 'src/modules/delivery';
import useLocalize from 'src/services/localize/useLocalize';
import { Typography } from 'src/ui-kit/Typography';


export interface Props {
  address: string;
  deliveryExtra?: string;
  deliveryType: DeliveryType;
}

export default function AddressDetailsText(props: Props) {
  const { address, deliveryExtra, deliveryType } = props;

  const localize = useLocalize();

  return (
    <Fragment>
      <Typography variant="text6">
        {address}
      </Typography>

      {deliveryType === 'plan' && (
        <Fragment>
          {!!deliveryExtra && (
            <Typography
              sbs={{  mb: '3px' }}
              variant="text6"
            >
              {deliveryExtra}
            </Typography>
          )}

          <Typography color='gray600' variant='text6'>
            {localize('address_info_plan')}
          </Typography>
        </Fragment>
      )}

      {deliveryType === 'pickup' && (
        <Typography color='gray600' variant='text6'>
          {deliveryExtra}
        </Typography>
      )}
    </Fragment>
  );
}
