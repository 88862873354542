import { ReactNode } from "react";
import Responsive from "src/components/Responsive";
import useLocalize from "src/services/localize/useLocalize";
import Button from 'src/ui-kit/Button';
import { useTheme } from "src/ui-kit/theme/UseTheme";
import Price from "src/UIKitOld/Price/Price";
import { OrdersTableItemStyled } from "./OrdersTableItem.styles";

export interface Props {
  id: string;
  deliveryTime: string;
  sum: number;
  status: string;
  orderLink: string;
  buttonSlot?: ReactNode;
}

const OrdersTableItem = (props: Props) => {
  const {
    id,
    deliveryTime,
    sum,
    status,
    buttonSlot,
    orderLink,
  } = props;

  const localize = useLocalize();
  const theme = useTheme();

  return (
    <OrdersTableItemStyled
      className="OrdersTableItem"
      data-marker={`Order item ${id}`}
      data-testid='OrdersTableItem'
      theme={theme}
    >
      <div className="OrdersTableItem__info">
        <div className="OrdersTableItem__cell OrdersTableItem__cell_id">
          <Responsive displayOn="phone">
            <span className="OrdersTableItem__title">
              {localize('general.number')}
            </span>
          </Responsive>
          {id}
        </div>
        <div className="OrdersTableItem__cell OrdersTableItem__cell_delivery">
          <Responsive displayOn="phone">
            <span className="OrdersTableItem__title">
              {localize('general.delivery')}
            </span>
          </Responsive>
          {deliveryTime}
        </div>
        <div className="OrdersTableItem__cell OrdersTableItem__cell_price">
          <Responsive displayOn="phone">
            <span className="OrdersTableItem__title">
              {localize('general.total')}
            </span>
          </Responsive>
          <Price price={sum} />
        </div>
        <div className="OrdersTableItem__cell OrdersTableItem__cell_status">
          <Responsive displayOn="phone">
            <span className="OrdersTableItem__title">
              {localize('general.status')}
            </span>
          </Responsive>
          {status}
        </div>
      </div>
      <div className="OrdersTableItem__buttons">
        {
          buttonSlot && (
            <span>
              {buttonSlot}
            </span>
          )
        }

        <span className="OrdersTableItem__buttonWrap">
          <Button
            dataMarker={`Details order ${id}`}
            href={orderLink}
            variant="ghost"
            target="_blank"
            width='auto'
          >
            {localize('general.details')}
          </Button>
        </span>
      </div>
    </OrdersTableItemStyled>
  );
};

export default OrdersTableItem;
