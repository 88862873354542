import styled from '@emotion/styled';
import { desktop, phone_tablet, tablet } from 'src/styles/media';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const AccountLayoutStyled = styled.div<StyledThemeProps>(({ theme }) => `
  font-family: ${theme.base.fontFamily.normal};
  background: ${theme.base.color.white};
  position: relative;
  overflow: hidden;

  & .AccountLayout__container {
    margin: 0 auto;
    max-width: 1168px;
    padding: 40px 16px 16px;
  }
  & .AccountLayout__sidebarTitle {
    ${typography.h3}
    padding-bottom: 12px;
    padding-left: 16px;
  }
  & .AccountLayout__content {
    width: 100%;
    padding-bottom: 30px;
  }

  @media ${tablet} {
    & .AccountLayout__container {
      padding: 31px 34px 16px;
    }
  }

  @media ${phone_tablet} {
    & .AccountLayout__sidebar {
      margin-left: -16px;
    }
  }

  @media ${desktop} {
    & .AccountLayout__container {
      display: flex;
      padding-top: 24px;
    }
    & .AccountLayout__sidebar {
      width: 249px;
      flex-shrink: 0;
      flex-grow: 0;
      border-left: 1px solid ${theme.base.color.gray200};
      padding-top: 4px;
    }
    & .AccountLayout__content {
      padding: 0 32px 20px;
      min-height: 500px;
    }
  }
`);

