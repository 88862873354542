import { useState } from "react";
import Tabs from "src/components/Tabs/Tabs";
import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import SavedAddressesTab from "../SavedAddressesTab/SavedAddressesTab";

const AddressTypeTabs = () => {
  const localize = useLocalize();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const sbsTapPanel = {
    minHeight: '0',
    flexGrow: '1',
  };

  return (
    <StylishBox
      className="AddressTypeTabs"
      sbs={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StylishBox sbs={{ mb: '16px' }}>
        <Tabs
          selectedIndex={selectedIndex}
          onSelect={setSelectedIndex}
          tabs={[
            {
              label: localize('address.saved'),
              dataMarker: 'Saved',
            },
            {
              label: localize('address.new'),
              dataMarker: 'New address',
            },
          ]}
        />
      </StylishBox>

      {selectedIndex === 0 && (
        <StylishBox
          className="TabPanel"
          sbs={sbsTapPanel}
        >
          <SavedAddressesTab />
        </StylishBox>
      )}

      {selectedIndex === 1 && (
        <StylishBox
          className="TabPanel"
          sbs={sbsTapPanel}
        >
          new
        </StylishBox>
      )}
    </StylishBox>
  );
};

export default AddressTypeTabs;

