import { fetcher } from "src/api/fetch";
import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import { UserSubscriptionItem } from "../hooks/useUserMarketingSubscriptions/useUserMarketingSubscriptions";

export async function requestGetUserSubscriptions() {
  const response = await fetcher<UserSubscriptionItem[]>(
    `/user/profile/subscriptions/`,
    {
      credentials: 'include',
    },
  );

  return getDataFromResponse(response, []);
}

export default requestGetUserSubscriptions;
