import { ApiErrorShape } from "src/api/types";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";

const getErrorText = (errors: ApiErrorShape[], localize: LocalizeFunc) => {
  if (!errors?.length) {
    return '';
  }

  const { error_code, field } = errors[0];

  if (error_code === 4020 && field === 'captcha_token') {
    return localize(`error.code.4024.captcha`);
  }

  return error_code === 4024 || error_code === 4020
    ? localize(`error.code.4024`) // enter phone as +38 055 555...
    : localize(`error.code.${error_code}`);
};

export default getErrorText;
