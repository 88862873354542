import { useState } from "react";
import { ApiErrorShape } from "src/api/types";
import { useModals } from 'src/modules/modals';
import useUserProfile from "src/services/userProfile/useUserProfile";
import requestPostHorecaRegistration from "../../ajax/requestPostHorecaRegistration";
export type RegistrationStep = 'stepOne' | 'stepTwo';

export interface RegistrationBusinessType {
  value: string;
  label: string;
}

export interface FieldsHorecaRegistration {
  businessType: string;
  companyName: string;
}

const useHorecaRegistration = () => {
  const [requestErrors, setRequestErrors] = useState<ApiErrorShape[]>([]);
  const { openModal } = useModals();
  const { getUserProfile } = useUserProfile();
  const [isLoading, setIsLoading] = useState(false);

  const [fields, setFields] = useState<FieldsHorecaRegistration>({
    companyName: '',
    businessType: '',
  });

  const handleFieldChange = (data: Partial<FieldsHorecaRegistration>) => {
    const result = { ...fields, ...data };

    setFields(result);
  };

  const handleRegistration = async () => {
    setRequestErrors([]);

    setIsLoading(true);

    const response = await requestPostHorecaRegistration({
      business_type: fields.businessType || '',
      name: fields.companyName,
    });

    if (response?.errors) {
      setRequestErrors(response.errors);
    } else {
      getUserProfile();

      openModal(
        'registrationSuccessModal',
        {
          phone: '',
          isHorecaUser: true,
        },
      );
    }

    setIsLoading(false);
  };

  return {
    fields,
    handleFieldChange,
    handleRegistration,
    requestErrors,
    isLoading,
  };
};

export default useHorecaRegistration;
