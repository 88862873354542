import styled from '@emotion/styled';
import { phone } from 'src/styles/media';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const AccountAddressItemStyled = styled.div<StyledThemeProps>(({ theme }) => `
  border-radius: ${theme.base.borderRadii.v1};
  border: 1px solid ${theme.base.color.gray200};
  padding: 16px;
  margin-bottom: 16px;
  line-height: 24px;

  .AccountAddressItem__header {
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
  }
  .AccountAddressItem__title {
    ${typography.h6}
  }
  .AccountAddressItem__buttons {
    display: flex;
  }
  .AccountAddressItem__button_withMarginRight {
    margin-right: 24px;
  }

  @media ${phone} {
    .AccountAddressItem__header {
      margin-bottom: 10px;
      align-items: center;
    }

    .AccountAddressItem__buttonIcon {
      font-size: 18px;
    }
  }
`);
