import LoadingRing from "src/components/Loaders/LoadingRing/LoadingRing";
import useLocalize from "src/services/localize/useLocalize";
import { useTheme } from "src/ui-kit/theme";
import useOrders from "../../hooks/useOrders/useOrders";
import { AccountPageInitParams } from "../../types";
import AccountEmptyDataPlaceholder from "../AccountEmptyDataPlaceholder/AccountEmptyDataPlaceholder";
import ChainsSelect from "../ChainsSelect/ChainsSelect";
import useChainsSelect from "../ChainsSelect/useChainsSelect";
import OrdersTable from "../OrdersTable/OrdersTable";

const OrdersPageContent = (props: AccountPageInitParams) => {
  const { chains, lastVisitChainId, profileId } = props;
  const theme = useTheme();
  const localize = useLocalize();
  const { chainsOptions, activeChain, handleOptionChange } = useChainsSelect({ chains, lastVisitChainId });
  const { orders, ordersAreFetching: isFetching } = useOrders(activeChain.instance.id, profileId);
  const ordersTableIsVisible = !isFetching && (orders.length > 0);
  const noOrdersIsVisible = !isFetching && (orders.length === 0);

  return (
    <div className="OrderPageContent">
      <ChainsSelect
        options={chainsOptions}
        activeValue={activeChain}
        onChange={handleOptionChange}
      />
      {
        isFetching && <LoadingRing color={theme.base.color.primary} size="60" />
      }

      {
        ordersTableIsVisible && <OrdersTable orders={orders} chainHostname={activeChain.instance.hostname} />
      }

      {
        noOrdersIsVisible && <AccountEmptyDataPlaceholder text={localize('no_orders')} />
      }
      <style jsx>{`
        .OrderPageContent {
          min-height: 300px;
        }
      `}</style>
    </div>
  );
};

export default OrdersPageContent;
