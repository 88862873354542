import useLocalize from "src/services/localize/useLocalize";
import Button from "src/ui-kit/Button";
import IconFont from "src/ui-kit/IconFont";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";
import { Typography } from "src/ui-kit/Typography";
import useUpdateQueryParameter from "../../hooks/useUpdateQueryParameter";
import CatalogActiveFiltersList from "../CatalogActiveFiltersList/CatalogActiveFiltersList";
import { ActiveFilter } from "src/utils/catalog/getActiveFilters";

interface CatalogFiltersEmptyProps {
  activeFilters: ActiveFilter[];
}

const CatalogFiltersEmpty = ({ activeFilters }: CatalogFiltersEmptyProps) => {
  const theme = useTheme();
  const localize = useLocalize();

  const { removeLastFilter, clearAllQueryParameters, updateQueryParameter } = useUpdateQueryParameter();

  return (
    <StylishBox dataMarker="CatalogFiltersEmpty">
      <CatalogActiveFiltersList
        activeFilters={activeFilters}
        onClearAll={clearAllQueryParameters}
        onDeleteItem={updateQueryParameter}
      />

      <StylishBox
        sbs={{
          backgroundColor: theme.base.color.white,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: '16px',
          p: '40px',
          height: {
            general: '276px',
            phone: '224px',
          },
        }}
      >
        <IconFont
          name="filters"
          color="gray400"
          sbs={{
            mb: '16px',
            fontSize: {
              general: '40px',
              phone: '32px',
            },
          }}
        />
        <Typography variant="h5" align="center">
          {localize('products.empty')}
        </Typography>
        <Button
          variant="primary"
          onClick={removeLastFilter}
          sbs={{
            maxWidth: '245px',
            mt: '16px',
          }}
          dataMarker="Remove Last Filter Button"
        >
          {localize('products.empty.button')}
        </Button>
      </StylishBox>
    </StylishBox>
  );
};

export default CatalogFiltersEmpty;
