import { HORECA_B2B_LINK } from 'src/data/constants';
import useLocalize from 'src/services/localize/useLocalize';
import { useTheme } from 'src/ui-kit/theme';
import IconFont from '../../../../ui-kit/IconFont';
import { HorecaDocumentsStyled } from './HorecaDocuments.styles';

const HorecaDocuments = () => {
  const localize = useLocalize();
  const theme = useTheme();
  const steps = [
    'horeca_step_0',
    'horeca_step_1',
    'horeca_step_2',
    'horeca_step_3',
    'horeca_step_4',
  ];

  const phone = localize('horeca_phone_label');
  const email = localize('horeca_email_value');

  return (
    <HorecaDocumentsStyled
      className="HorecaDocuments"
      data-testid="horeca-documents"
      theme={theme}
    >
      <div className="HorecaDocuments__explanation">
        {localize('horeca_step_title')}
      </div>
      <div className="HorecaDocuments__explanation">
        {localize('horeca_step_advantages')}
      </div>

      <ol className="HorecaDocuments__steps">
        {steps.map((translationsKey) => (
          <li className="HorecaDocuments__stepsItem" key={translationsKey}>
            <IconFont
              name='check'
              color='primary'
              size='16px'
              sbs={{ mr: '8px' }}
            />
            {localize(translationsKey)}
          </li>
        ))}
      </ol>

      <div className="HorecaDocuments__questionText">
        {localize('horeca_have_question_text')}
      </div>

      <a
        href={HORECA_B2B_LINK}
        target="_blank"
        rel="noreferrer"
        className='HorecaDocuments__outsideLink'
        data-marker="B2B link"
      >
        {HORECA_B2B_LINK}
      </a>
      <div className='HorecaDocuments__contacts'>
        <a
          className='HorecaDocuments__contactsItem'
          href={`mailto:${email}`}
        >
          {email}
        </a>
        <a
          className='HorecaDocuments__contactsItem'
          href={`tel:${phone}`}
        >
          {phone}
        </a>
      </div>
    </HorecaDocumentsStyled>
  );

};

export default HorecaDocuments;
