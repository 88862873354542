import styled from "@emotion/styled";
import { phone } from "src/styles/media";
import { StyledThemeProps } from "src/ui-kit/theme/types";

export const TabsPanelStyled = styled.div<StyledThemeProps>(({ theme }) => `
  .TabsPanel__buttons {
    border-bottom: ${theme.base.border.v2} ${theme.base.color.gray300};
    margin-bottom: 24px;
  }

  .TabsPanel__button {
    cursor: pointer;
    background: transparent;
    padding: 8px 24px 12px;
    border: 0;
    font-size: 16px;
    font-weight: 400;
    color: ${theme.base.color.gray600};
  }
  .TabsPanel__button_active {
    position: relative;
    font-weight: 600;
    color: ${theme.base.color.primary};
  }
  .TabsPanel__button_active:before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 0;
    background-color: ${theme.base.color.primary};
  }

  @media ${phone} {
    .TabsPanel__buttons {
      display: flex;
    }

    .TabsPanel__button {
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 16px;
      padding: 6px 12px 8px;
    }
  }
`);
