import { Fragment, ReactNode, useEffect } from 'react';
import useAuth from '../../../../services/auth/useAuth';
import LoadingBarIndicator from 'src/components/LoadingBarIndicator/LoadingBarIndicator';

interface Props {
  onUnauthorized(): void;
  children: ReactNode;
}

const ForAuthorizedUsers = (props: Props) => {
  const {
    children,
    onUnauthorized,
  } = props;

  const { isLoggedIn, isFetching, loginIsChecked } = useAuth();
  const loginStatusIsChecking = isFetching || !loginIsChecked;
  const userIsNotAuthorized = !isLoggedIn && !loginStatusIsChecking;

  useEffect(() => {
    if (userIsNotAuthorized) {
      onUnauthorized();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userIsNotAuthorized],
  );

  if (isLoggedIn) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  if (loginStatusIsChecking) {
    return (
      <div className='LoaderWrapper'>
        <LoadingBarIndicator />
        <style jsx>{`
          .LoaderWrapper{
            height: calc(100vh - 600px); /*approximate sum of height of the footer and header */
          }
        `}</style>
      </div>
    );
  }

  return null;
};

export default ForAuthorizedUsers;
