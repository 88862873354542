import { useTheme } from 'src/ui-kit/theme';
import ChangePasswordForm from '../../../../components/ChangePasswordForm/ChangePasswordForm';
import GeneralModal from '../../../../components/GeneralModal/GeneralModal';
import useLocalize from '../../../../services/localize/useLocalize';
import useChangePassword from '../../hooks/useChangePassword/useChangePassword';
import { ChangePasswordModalStyled } from './ChangePasswordModal.styles';

export interface ChangePasswordModalProps {
  onClose(): void;
}

const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const { onClose } = props;
  const {
    isFetching,
    fields,
    handleFieldChange,
    fieldsErrorMap,
    submitIsDisabled,
    handleSaveNewPassword,
  } = useChangePassword(onClose);

  const theme = useTheme();
  const localize = useLocalize();

  return (
    <GeneralModal
      onClose={onClose}
      isOpen={true}
      withCloseButton={true}
      isFetching={isFetching}
    >
      <ChangePasswordModalStyled
        theme={theme}
        className='ChangePasswordModal'
        data-marker-popup='Change the password for login'
        data-testid='ChangePasswordModal'
      >
        <div className='ChangePasswordModal__title'>
          <span>{localize('account.change_password')}</span>
        </div>

        <ChangePasswordForm
          fields={fields}
          fieldsErrorMap={fieldsErrorMap}
          onFieldChange={handleFieldChange}
          onSubmit={handleSaveNewPassword}
          submitIsDisabled={submitIsDisabled}
        />
      </ChangePasswordModalStyled>
    </GeneralModal>
  );
};

export default ChangePasswordModal;
