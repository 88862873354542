import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import typography from '../../styles/typography';

export const styles = css`
  .PhoneInput__label {
    display: block;
    ${typography.h9}
    margin-bottom: 2px;
  }
  .PhoneInput__error {
    margin-top: 4px;
  }
`;

export const getInputResolve = (theme: Theme) => {
  return css.resolve`
    input[type='tel'] {
      width: 100%;
      border: 1px solid ${theme.base.color.gray300};
      border-radius: 4px;
      display: block;
      height: 40px;
      font-size: 16px;
      font-weight: 400;
      font-family: ${theme.base.fontFamily.normal};
      color: ${theme.base.color.dark};
    }
    input[type='tel']:disabled {
      color: ${theme.base.color.gray500};
      background: ${theme.base.color.gray100};
    }
    input[type='tel'].invalid-number,
    input[type='tel'].invalid-number:hover,
    input[type='tel'].invalid-number:focus {
      border-color: ${theme.base.color.error};
      background: ${theme.base.color.white};
    }

    input[type='tel']:focus:not(.invalid-number) {
      border: 1px solid ${theme.base.color.gray500};
    }

    input[type='tel']:focus:not(.invalid-number) + :global(.flag-dropdown) {
      border-color: ${theme.base.color.gray500};
    }
  `;
};

export const getContainerResolve = (theme: Theme) => {
  return css.resolve`
    div :global(.flag-dropdown) {
      background-color: ${theme.base.color.white};
      border: 1px solid ${theme.base.color.gray300};
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    div :global(.flag-dropdown):hover {
      cursor: default;
      background-color: transparent !important;
    }
    div :global(.flag-dropdown.invalid-number) {
      border-color: ${theme.base.color.error};
    }
    div :global(.flag-dropdown .selected-flag) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    div :global(.flag-dropdown .flag) {
      left: 12px;
    }
    div.react-tel-input_disabled :global(.flag-dropdown),
    div.react-tel-input_disabled :global(.flag-dropdown:hover) {
      background: ${theme.base.color.gray100};
      cursor: not-allowed;
    }
  `;
};
