import styled from '@emotion/styled';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const HorecaRegistrationStyled = styled.div<StyledThemeProps>(({ theme }) => `
  position: relative;

  .HorecaRegistration__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
  }

  .HorecaRegistration__form {
    margin-top: 24px;
    max-width: 330px;
  }

  .HorecaRegistration__input {
    margin-bottom: 16px;
  }

  .HorecaRegistration__label {
    display: inline-block;
    ${typography.h9}
    margin-bottom: 5px;
  }

  .HorecaRegistration__errors {
    margin-top: 12px;
  }

  .HorecaRegistration__button {
    margin-top: 32px;
  }

  .HorecaRegistration__errors + .HorecaRegistration__button {
    margin-top: 12px;
  }

  .HorecaRegistration__link {
    ${typography.text};
    color: ${theme.base.color.primary};
    text-decoration: none;
  }
`);
