import classNames from "classnames";
import Image from "next/image";
import { CURRENCY } from "src/data/constants";
import { LinkWithLoading } from "src/modules/global-loading";
import useLang from "src/services/language/useLang";
import { useTheme } from "src/ui-kit/theme";
import { ProductPlace, sendProductClick } from "src/utils/enhancedEcommerce";
import getBundleInfo from "src/utils/products/getBundleInfo";
import { CatalogProductBase } from "../../../../api/types";
import PriceRange from "../../../../components/PricesRange/PricesRange";
import { useLocalize } from "../../../../services/localize/useLocalize";
import formatWeight from "../../../../utils/products/formatWeight";
import { getProductUrl } from "../../helpers/getProductUrl";
import { useProductBadges } from "../../hooks/useProductBadges";
import Badges from "../Badges/Badges";
import { CatalogProductTileStyled } from "./CatalogProductTile.styles";

interface Props {
  product: CatalogProductBase;
  dataMarkerValue?: string;
  place: ProductPlace;
}

const CatalogProductTile = (props: Props) => {
  const { product, dataMarkerValue = 'CatalogProductTile', place } = props;
  const theme = useTheme();
  const localize = useLocalize();
  const lang = useLang();
  const badges = useProductBadges(product);

  const {
    price_details: {
      price_from: priceFrom,
      price_to: priceTo,
    },
    img,
    ean,
    unit,
    weight,
    volume,
    bundle,
    title,
    slug,
    in_stock: inStock,
  } = product;

  const viewWeight = formatWeight(unit, weight, volume, localize);
  const viewBundle = getBundleInfo(bundle, localize);

  return (
    <LinkWithLoading
      onClick={() => sendProductClick(product, place)}
      href={getProductUrl({ lang, ean, slug })}
    >
      <CatalogProductTileStyled
        theme={theme}
        className="CatalogProductTile"
        data-marker={dataMarkerValue}
      >
        <div
          className={classNames(
            'CatalogProductTile__image', {
            'CatalogProductTile__image_opacity': !inStock,
          })}
        >
          <div className="CatalogProductTile__responsiveImg">
            <Image
              src={img?.s350x350 || '/static/images/product_placeholder.jpg'}
              alt={title}
              fill
              style={{ objectFit: 'contain' }}
            />
          </div>
        </div>
        <Badges {...badges} straightLeft />

        {inStock ?
          (
            <p className="CatalogProductTile__price">
              <PriceRange startPrice={priceFrom} endPrice={priceTo} currency={CURRENCY} variant="small" />
            </p>
          )
          : (
            <p className="CatalogProductTile__price CatalogProductTile__price_unavailable">
              {localize('out_of_stock')}
            </p>
          )
        }

        <p className="CatalogProductTile__title" title={title}>
          {title}
        </p>

        <p className="CatalogProductTile__weight">
          {viewWeight}
          {viewBundle}
        </p>
      </CatalogProductTileStyled>
    </LinkWithLoading>
  );
};

export default CatalogProductTile;
