import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";
import AddressTypeTabs from "../AddressTypeTabs/AddressTypeTabs";

const SelectTheAddress = () => {
  const localize = useLocalize();

  return (
    <StylishBox
      className='SelectTheAddress'
      sbs={{
        display: 'flex',
        flexDirection: 'column',
        width: {
          general: '450px',
          tablet: '370px',
        },
        height: '100%',
        p: {
          general: '32px 36px 24px 40px',
          tablet: '32px 20px 24px',
        },
        position: 'relative',
      }}
    >
      <Typography
        sbs={{ mb: '12px' }}
        variant='h5'
        element='h2'
      >
        {localize('address_management_title') }
      </Typography>

      <Typography variant='text6' sbs={{ mb: '17px' }}>
        { localize('address_management_text') }
      </Typography>

      <StylishBox
        sbs={{
          flexGrow: '1',
          minHeight: '0',
        }}
      >
        <AddressTypeTabs />
      </StylishBox>
    </StylishBox>
  );
};

export default SelectTheAddress;
