import { ReactNode } from 'react';
import { Typography } from 'src/ui-kit/Typography';

interface Props {
  children: ReactNode;
  dataTestId?: string;
}

const ErrorMessage = (props: Props) => {
  const { children, dataTestId } = props;

  return (
    <Typography
      className='ErrorMessage'
      dataTestId={dataTestId}
      variant='caption1'
      color='error'
      sbs={{ m: '0' }}
    >
      {children}
    </Typography>
  );
};

export default ErrorMessage;
