import css from 'styled-jsx/css';
import { phone } from '../../../../styles/media';
import typography from '../../../../styles/typography';

const styles = css`
  .PersonalData {
    max-width: 330px;
    margin-bottom: 40px;
  }
  .PersonalData__title {
    ${typography.h3}
    margin-bottom: 16px;
  }
  .PersonalData__field {
    margin-bottom: 20px;
  }
  .PersonalData__button {
    margin-top: 32px;
  }

  @media ${phone} {
    .PersonalData {
      max-width: 100%;
    }
  }
`;

export default styles;
