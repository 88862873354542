import { DeliveryType } from "src/modules/delivery";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";

export const getAddressItemTitle = (deliveryType: DeliveryType, localize: LocalizeFunc) => {
  if(deliveryType === 'plan') {
    return localize('delivery.type.courier.long');
  }

  if(deliveryType === 'pickup') {
    return localize('delivery_type_pickup');
  }

  return '';
};


