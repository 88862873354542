import styled from '@emotion/styled';
import { phone, tablet } from 'src/styles/media';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const OrdersTableItemStyled = styled.div<StyledThemeProps>(({ theme }) => `
  padding: 12px 24px 16px;
  border-bottom: 1px solid ${theme.base.color.gray200};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${typography.text}

  .OrdersTableItem_grey {
    color: ${theme.base.color.gray500};
  }
  .OrdersTableItem__info{
    display: flex;
    align-items: center;
    width: 570px;
  }
  .OrdersTableItem__cell {
    margin-right: 10px;
  }
  .OrdersTableItem__cell:last-child {
    margin-right: 0;
  }
  .OrdersTableItem__cell_id {
    flex-basis: 110px;
  }
  .OrdersTableItem__cell_delivery {
    flex-basis: 190px;
  }
  .OrdersTableItem__cell_price {
    flex-basis: 125px;
  }
  .OrdersTableItem__buttonWrap {
    margin-left: 32px;
  }
  .OrdersTableItem__buttons {
    display: flex;
  }

  @media ${tablet} {
    .OrdersTableItem__info {
      width: 515px;
    }
    .OrdersTableItem__cell {
      margin-right: 5px;
    }
    .OrdersTableItem__cell_id {
      flex-basis: 90px;
    }
    .OrdersTableItem__cell_delivery {
      flex-basis: 180px;
    }
    .OrdersTableItem__cell_price {
      flex-basis: 115px;
    }
    .OrdersTableItem__buttonWrap {
      margin-left: 24px;
    }
  }

  @media ${phone} {
    display: block;
    padding: 18px 0 18px;

    .OrdersTableItem__info {
      display: block;
      width: 100%;
    }
    .OrdersTableItem__cell {
      display: flex;
      justify-content: space-between;
      margin: 0 0 16px;
    }
    .OrdersTableItem__title {
      color: ${theme.base.color.gray500};
    }
    .OrdersTableItem__buttonWrap {
      margin-right: 0;
      margin-left: auto;
    }
    .OrdersTableItem__buttons {
      justify-content: space-between;
    }
  }
`);
