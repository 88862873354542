import { fetcher } from 'src/api/fetch';
import { getDataFromResponse } from 'src/utils/api/getDataFromResponse';
import useSWR from 'swr';
import { BusinessTypes } from '../types';

const useBusinessTypes = (language: string) => {
  const { data, error } = useSWR(
    [language],
    ([language]) => getBusinessTypes(language),
  );

  const businessTypes = data || [];
  const businessTypesAreFetching = data === undefined && error === undefined;

  return {
    businessTypes,
    businessTypesAreFetching,
  };
};

export default useBusinessTypes;

export async function getBusinessTypes(language: string): Promise<BusinessTypes[]> {
  const response = await fetcher<BusinessTypes[]>(`/about/b2b/business_types/`, {
    language,
  });

  return getDataFromResponse(response, []);
}
