import { DeliveryExtraData } from "../components/AddressPlanEditModal/types";

export const maxLengthEnum = {
  BLOCK: 3,
  CHECKOUT_UPDATE_COMMENT: 1000,
  COMPANY_NAME: 100,
  ENTRANCE: 3,
  ENTRANCE_CODE: 8,
  FLOOR: 3,
  ROOM: 10,
  CORPS: 3,
  OFFICE: 10,
  BUILDING: 10,
  EDRPO: 10,
};

export default function validateExtraData(data: DeliveryExtraData) {
  const validatedData: DeliveryExtraData = { ...data };
  let hasErrors = false;

  for (const key in data) {
    const hasError = data[key].required ? !data[key].value : false;

    validatedData[key] = {
      ...data[key],
      hasError,
    };

    if (hasError) {
      hasErrors = true;
    }
  }

  return { validatedData, hasErrors };
}
