import styled from '@emotion/styled';
import { phone } from 'src/styles/media';
import { useTheme } from 'src/ui-kit/theme';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

interface ProductIngredientsProps {
  ingredients: string[];
}

export const StyledContainer = styled.div<StyledThemeProps>(({ theme }) => `
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 12px;
    border: ${theme.base.border.v1} ${theme.base.color.gray300};
    text-align: left;
  }

  strong {
    font-weight: 600;
  }

  @media ${phone} {
    th, td {
      padding: 4px;
    }
  }
`);

const ProductIngredients = ({ ingredients }: ProductIngredientsProps) => {
  const text = ingredients.join(', ');
  const theme = useTheme();

  return (
    <StyledContainer
      theme={theme}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default ProductIngredients;
