import GeneralModal from "src/components/GeneralModal/GeneralModal";
import useLocalize from "src/services/localize/useLocalize";
import Button from "src/ui-kit/Button";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";
import { useTheme } from "src/ui-kit/theme";
import requestDeleteDeliveryPreset from "../../ajax/requestDeleteDeliveryPreset";
import { useUserDeliveryPresets } from "../../hooks/useUserDeliveryPresets/useUserDeliveryPresets";
import { getAddressInfo } from "../../utils/getAddressInfo";
import { getAddressItemTitle } from "../../utils/getAddressItemTitle";
import AccountAddressesInfo from "../AccountAddressesInfo/AccountAddressesInfo";
import { ServerCartDelivery } from "src/services/userDelivery/types";


export interface AddressesDeleteModalProps {
  onClose: () => void;
  address: ServerCartDelivery;
  chainId: string;
  profileId: string;
}

const AddressesDeleteModal = (props: AddressesDeleteModalProps) => {
  const { onClose, address, chainId, profileId } = props;
  const { deliveryPresets, deliveryPresetsMutate } = useUserDeliveryPresets(chainId, profileId);

  const localize = useLocalize();
  const theme = useTheme();

  const title = getAddressItemTitle(address.type, localize);
  const addressInfo = getAddressInfo(address, localize);

  const deleteAddress = async () => {
    const addressHash = address.hash;
    await requestDeleteDeliveryPreset(addressHash, chainId);

    // Обновляем кеш: удаляем элемент локально
    deliveryPresetsMutate(deliveryPresets.filter((item) => item.hash !== addressHash));
    onClose();
  };

  return (
    <GeneralModal
      onClose={onClose}
      isOpen
      withCloseButton={true}
    >
      <StylishBox
        dataTestId="account_addresses_delete_modal"
        elementProps={{
          'data-marker-popup': 'Delete Address',
        }}
        sbs={{
          width: '410px',
          maxWidth: '100%',
          p: {
            general: '40px',
          },
          px: {
            phone: '16px',
          },
          backgroundColor: theme.base.color.white,
        }}
      >
        <Typography variant="h5" align="center">
          {localize('addresses.delete')}
        </Typography>

        <Typography
          variant="text5"
          sbs={{
            mt: '16px',
            mb: '8px',
          }}
        >
          {title}
        </Typography>

        <AccountAddressesInfo
          addressText={addressInfo.address}
          detailsText={addressInfo.extra}
        />

        <StylishBox
          sbs={{
            display: 'flex',
            mt: '24px',
          }}
        >
          <Button
            width="100%"
            onClick={onClose}
            dataMarker='no'
            variant='secondary'
          >
            {localize('general.no')}
          </Button>
          <StylishBox
            sbs={{ width: '16px', flexShrink: '0' }}
          />
          <Button
            width="100%"
            variant='primary'
            type='button'
            onClick={deleteAddress}
            dataMarker='yes'
          >
            <span>{localize('confirm_delete')}</span>
          </Button>
        </StylishBox>
      </StylishBox>

    </GeneralModal>
  );
};

export default AddressesDeleteModal;
