import useLocalize from "src/services/localize/useLocalize";
import { ServerCartDelivery } from "src/services/userDelivery/types";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import AddressPresetItem from "./AddressPresetItem";

export interface AddressPresetListProps {
  presets: ServerCartDelivery[];
  selectedPresetId: string;
  onSelectPreset(preset: ServerCartDelivery): void;
  hasError?: boolean;
}

const AddressPresetList = (props: AddressPresetListProps) => {
  const { presets, selectedPresetId, hasError, onSelectPreset } = props;

  const localize = useLocalize();

  return (
    <StylishBox
      className='AddressPresetList'
      sbs={{
        overflow: 'auto',
        mb: '16px',
        flexGrow: '1',
        minHeight: '0',
      }}
    >
      {presets.map((preset) => (
        <AddressPresetItem
          key={preset.hash}
          preset={preset}
          isChecked={preset.hash === selectedPresetId}
          onClick={() => onSelectPreset(preset)}
          hasError={hasError && selectedPresetId === preset.hash}
          errorMessage={localize('delivery_address_is_not_available')}
        />
      ))}
    </StylishBox>
  );
};

export default AddressPresetList;
