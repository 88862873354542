import styled from '@emotion/styled';
import typography from '../../styles/typography';

export const BlockSuccessStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .BlockSuccess__title {
    ${typography.h3}
    margin: 15px 0;
  }
  .BlockSuccess__description {
    margin-bottom: 24px;
    font-size: 14px;
  }
  .BlockSuccess__button {
    width: 100%;
  }
`;

