import { useEffect, useMemo, useState } from 'react';
import { SubscriptionType, SubscriptionsSettings } from 'src/services/userProfile/types';
import { requestGetUserSubscriptions } from '../../ajax/requestGetUserSubscriptions';
import { requestUpdateSubscriptions } from '../../ajax/requestUpdateSubscriptions';
import { allMarketingSubscriptionsAreTrue, getUpdatedMarketingItem } from './helpers';

export interface UserSubscriptionItem {
  chain_id: string;
  subscriptions: SubscriptionsSettings;
}

const useUserMarketingSubscriptions = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useState<UserSubscriptionItem[]>([]);
  const [allChainsIsChecked, setAllChainsIsChecked] = useState(false);
  const zakazSubscriptions = useMemo(() => userSubscriptions.find(i => i.chain_id === 'zakaz'), [userSubscriptions]) as UserSubscriptionItem;
  const chainSubscriptions = useMemo(() => userSubscriptions.filter(i => i.chain_id !== 'zakaz'), [userSubscriptions]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setIsFetching(true);
      const subscriptions = await requestGetUserSubscriptions();
      setUserSubscriptions(subscriptions);
      setIsFetching(false);
    };

    if (!userSubscriptions.length) {
      fetchSubscriptions();
    }
  }, [userSubscriptions]);

  useEffect(() => {
    if (chainSubscriptions.length) {
      setAllChainsIsChecked(allMarketingSubscriptionsAreTrue(chainSubscriptions));
    }
  }, [chainSubscriptions]);

  const updateSubscriptions = async (subscriptions: UserSubscriptionItem | UserSubscriptionItem[]) => {
    if (!isFetching) {
      const subscriptionsArray = Array.isArray(subscriptions) ? subscriptions : [subscriptions];
      setIsFetching(true);
      const newSubscriptions = await requestUpdateSubscriptions(subscriptionsArray);
      setUserSubscriptions(newSubscriptions);
      setIsFetching(false);
    }
  };

  const handleClickAllChainsCheckbox = async () => {
    const allEqualMarketingSubscriptions = chainSubscriptions.map(i => getUpdatedMarketingItem(i, !allChainsIsChecked, 'marketing'));
    await updateSubscriptions(allEqualMarketingSubscriptions);
  };

  const handleClickChainCheckbox = async (item: UserSubscriptionItem) => {
    await updateSubscriptions(getUpdatedMarketingItem(item, !item.subscriptions.marketing, 'marketing'));
  };

  const handleClickZakazCheckbox = async (type: SubscriptionType) => {
    await updateSubscriptions(getUpdatedMarketingItem(zakazSubscriptions, !zakazSubscriptions.subscriptions[type], type));
  };

  return {
    chainSubscriptions,
    allChainsIsChecked,
    zakazSubscriptions,
    handleClickChainCheckbox,
    handleClickAllChainsCheckbox,
    handleClickZakazCheckbox,
  };
};

export default useUserMarketingSubscriptions;
