import { useMemo, useState } from "react";
import { RetailChain } from "src/api/types";
import { useSessionStorage } from 'usehooks-ts';
import { getChainOption } from "../../utils/getChainOption";
import { ChainOption } from "./ChainsSelect";

interface Params {
  chains: RetailChain[];
  lastVisitChainId: string;
}

const useChainsSelect = (params: Params) => {
  const { chains, lastVisitChainId } = params;
  const [chainIdSessionStorage, setChainIdSessionStorage] = useSessionStorage<string>('accountChainId', lastVisitChainId);
  const defaultChain = chains.find(i => i.id === chainIdSessionStorage) || chains[0];
  const [activeChain, setActiveChain] = useState(getChainOption(defaultChain));
  const chainsOptions = useMemo(() => chains.map(getChainOption), [chains]);

  const handleOptionChange = (option: ChainOption) => {
    setChainIdSessionStorage(option.value);
    setActiveChain(option);
  };

  return {
    chainsOptions,
    activeChain,
    handleOptionChange,
  };
};

export default useChainsSelect;
