'use client';
import ScriptLdJson from 'src/components/ScriptLdJson/ScriptLdJson';
import useLang from 'src/services/language/useLang';
import useLocalize from 'src/services/localize/useLocalize';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { UberCatalogProduct } from '../../../../api/types';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import ProductMainInfo from '../../components/ProductMainInfo/ProductMainInfo';
import ProductPageTabs from '../../components/ProductPageTabs/ProductPageTabs';
import UberCatalogProductsSlider from '../../components/UberCatalogProductsSlider/UberCatalogProductsSlider';
import { generateProductSchema } from '../../helpers/generateProductSchema';
import useProductPageProps from '../../hooks/useProductPageProps';

export interface ProductPageProps {
  product: UberCatalogProduct;
  sliders: {
    similar: UberCatalogProduct[];
    recommended: UberCatalogProduct[];
  };
}

const ProductPage = (props: ProductPageProps) => {
  const { product, sliders } = props;
  const lang = useLang();
  const localize = useLocalize();

  const {
    breadcrumbs,
    tabsProps,
    mainInfoProps,
  } = useProductPageProps(product);

  const microMarking = generateProductSchema({
    product,
    lang,
    localize,
  });

  const { inStock } = mainInfoProps;
  const hasSimilarProducts = sliders.similar.length > 0;
  const hasRecommendedProducts = sliders.recommended.length > 0;

  const similarSlider = (
    <UberCatalogProductsSlider
      title={localize(inStock ? 'product_similar_products' : 'product_not_available')}
      products={sliders.similar}
      place='Product card - similar'
      dataMarker='Similar'
    />
  );

  return (
    <StylishBox
      className='ProductPage'
      sbs={{
        pb: '50px',
        px: {
          tablet: '15px',
        },
      }}
    >
      <Breadcrumbs items={breadcrumbs} />

      {!inStock && hasSimilarProducts && (
        <StylishBox sbs={{ mb: '40px' }}>
          {similarSlider}
        </StylishBox>
      )}

      <ProductMainInfo {...mainInfoProps} />
      <ScriptLdJson body={microMarking} />
      <ProductPageTabs {...tabsProps} />

      {inStock && hasSimilarProducts && (
        <StylishBox
          sbs={{
            mb: hasRecommendedProducts ? '56px' : '0',
          }}
        >
          {similarSlider}
        </StylishBox>
      )}

      {hasRecommendedProducts && (
        <UberCatalogProductsSlider
          title={localize('product_buy_together_products')}
          products={sliders.recommended}
          place='Product card - buy with'
          dataMarker='Recommended'
        />
      )}
    </StylishBox>
  );
};

export default ProductPage;
