import css from 'styled-jsx/css';
import { phone } from '../../../../styles/media';
import typography from '../../../../styles/typography';

const styles = css`
  .LoginSettings {
    max-width: 450px;
    margin-bottom: 40px;
  }
  .LoginSettings__field {
    margin-bottom: 16px;
  }
  .LoginSettings__fieldRow {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .LoginSettings__input {
    width: 330px;
    margin-right: 16px;
  }
  .LoginSettings__title {
    ${typography.h4}
    margin-bottom: 16px;
  }

  @media ${phone} {
    .LoginSettings {
      max-width: 100%;
    }
    .LoginSettings__fieldRow {
      display: block;
    }
    .LoginSettings__input {
      margin-bottom: 8px;
      margin-right: 0;
      width: 100%;
    }
  }
`;

export default styles;
