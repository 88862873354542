import { fetcher } from 'src/api/fetch';

interface RequestParams {
  phone: string;
  password: string;
  token?: string;
}

const requestPostProfilePhoneLogin = async (params: RequestParams) => {
  const { token, password, phone } = params;
  const bodyData = JSON.stringify({ phone, password, captcha_token: token });

  const response = await fetcher<void>(`/user/profile/phones/login/`, {
    method: 'POST',
    credentials: 'include',
    body: bodyData,
  });

  return response;
};

export default requestPostProfilePhoneLogin;
