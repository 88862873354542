import { useState } from "react";
import { useModals } from 'src/modules/modals';
import useAuth from "src/services/auth/useAuth";
import { handleResponseWithRetry } from "src/utils/api/handleResponseWithRetry";
import useRecaptcha from "src/utils/useRecaptcha/useRecaptcha";
import { ApiErrorShape } from "../../../api/types";
import { getPurePhone } from "../../account/utils/getPurePhone";
import requestPostConfirmPasswordRecovery from "../ajax/requestPostConfirmPasswordRecovery";
import requestPostCreatePassword from "../ajax/requestPostCreatePassword";
import requestPostUserPasswordRecovery from "../ajax/requestPostUserPasswordRecovery";

type ModalFieldKeys = 'phone' | 'new_password' | 'new_password_confirm' | 'otp';

type FieldsState = {
  [key in ModalFieldKeys]: string;
}

const useForgotLogin = () => {
  const { getRecaptchaToken, recaptchaIsReady } = useRecaptcha('login_recovery');
  const { checkIsLoggedIn } = useAuth();
  const { closeModal } = useModals();
  const [retryTimeout, setRetryTimeout] = useState(0);
  const [requestErrors, setRequestErrors] = useState<ApiErrorShape[]>([]);
  const [step, goToStep] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  const [fields, setFields] = useState<FieldsState>({
    phone: '',
    otp: '',
    new_password: '',
    new_password_confirm: '',
  });

  const handleFieldChange = (field: ModalFieldKeys, newValue: string) => {

    if (requestErrors.length > 0) {
      setRequestErrors([]);
    }

    setFields({
      ...fields,
      [field]: newValue,
    });
  };

  const changeStateOnRequestStart = () => {
    setIsFetching(true);
    setRetryTimeout(0);
    setRequestErrors([]);
  };

  const handleSubmitFirstStep = async () => {
    const { phone } = fields;

    changeStateOnRequestStart();

    const token = await getRecaptchaToken();

    const response = await requestPostUserPasswordRecovery({
      phone: getPurePhone(phone),
      token,
    });

    setIsFetching(false);

    handleResponseWithRetry({
      response,
      onError: setRequestErrors,
      onRetry: setRetryTimeout,
      onSuccess: () => goToStep(2),
    });
  };

  const handleSubmitSecondStep = async (otp: string) => {
    const { phone } = fields;

    setIsFetching(true);
    setRequestErrors([]);

    const response = await requestPostConfirmPasswordRecovery({ phone, otp });

    setIsFetching(false);

    if (!response?.errors) {
      goToStep(3);
      // save opt code for third form step
      handleFieldChange('otp', otp);
    } else {
      setRequestErrors(response?.errors);
    }
  };

  const handleSubmitThirdStepStep = async () => {
    changeStateOnRequestStart();

    const response = await requestPostCreatePassword({
      otp: fields.otp,
      phone: fields.phone,
      password: fields.new_password,
      password2: fields.new_password_confirm,
    });

    setIsFetching(false);

    if (!response?.errors) {
      closeModal('forgotLoginModal');
      checkIsLoggedIn();
    } else {
      setRequestErrors(response?.errors);
    }
  };

  const handleChangeStep = (step: number) => {
    if (requestErrors.length > 0) {
      setRequestErrors([]);
    }

    goToStep(step);
  };

  return {
    fields,
    handleFieldChange,
    handleSubmitFirstStep,
    handleSubmitSecondStep,
    handleSubmitThirdStepStep,
    retryTimeout,
    requestErrors,
    step,
    handleChangeStep,
    isFetching: isFetching || !recaptchaIsReady,
  };
};

export default useForgotLogin;
