import Button from 'src/ui-kit/Button';
import { useTheme } from "src/ui-kit/theme";
import GeneralModal from "../../../../components/GeneralModal/GeneralModal";
import useLocalize from "../../../../services/localize/useLocalize";
import useRemovePhone from "../../hooks/useRemovePhone/useRemovePhone";
import { RemovePhoneModalStyled } from "./RemovePhoneModal.styles";

interface Props {
  onClose(): void;
  phone: string;
}

const RemovePhoneModal = (props: Props) => {
  const { phone, onClose } = props;
  const theme = useTheme();
  const localize = useLocalize();
  const { handleRemovePhone, isFetching } = useRemovePhone(phone, onClose);

  return (
    <GeneralModal
      onClose={onClose}
      isOpen={true}
      withCloseButton={true}
      isFetching={isFetching}
    >
      <RemovePhoneModalStyled
        className='RemovePhoneModal'
        data-marker-popup='Remove phone modal'
        data-testid='RemovePhoneModal'
        theme={theme}
      >
        <div className='RemovePhoneModal__title'>
          {localize('account.removing_phone')}
        </div>
        <div className='RemovePhoneModal__description'>
          {localize('account.remove_phone_info')}
          <span
            className='RemovePhoneModal__phone'
            data-marker='Removing phone'
          >{` +${phone} `}</span>?
        </div>
        <div className="RemovePhoneModal__buttons">
          <Button
            variant="secondary"
            onClick={onClose}
            dataMarker='No'
          >
            {localize('general.no')}
          </Button>
          <div className="RemovePhoneModal__separator" />
          <Button
            variant="primary"
            dataMarker='Yes'
            onClick={handleRemovePhone}
          >
            {localize('general.yes')}
          </Button>
        </div>
      </RemovePhoneModalStyled>
    </GeneralModal>
  );
};

export default RemovePhoneModal;
