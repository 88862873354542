import { ServerCartDeliveryPlan } from 'src/services/userDelivery/types';
import { DeliveryExtraData } from '../components/AddressPlanEditModal/types';

export default function convertDeliveryToExtraData(
  initialData: ServerCartDeliveryPlan,
): DeliveryExtraData {
  const planType = initialData.type;

  return {
    block: {
      value: initialData.block || '',
      name: 'block',
    },
    room: {
      value: initialData.room || '',
      name: 'room',
      required: planType === 'apartment',
    },
    floor: {
      value: initialData.floor || '',
      name: 'floor',
      required: planType !== 'house',
    },
    company_name: {
      value: initialData.company_name || '',
      name: 'company_name',
      required: planType === 'office',
    },
    entrance: {
      value: initialData.entrance || '',
      name: 'entrance',
      required: planType === 'apartment',
    },
    entrance_code: {
      value: initialData.entrance_code || '',
      name: 'entrance_code',
    },
    elevator: {
      value: initialData.elevator || false,
      name: 'elevator',
    },
    comments: {
      value: initialData.comments || '',
      name: 'comments',
    },
  };
}
