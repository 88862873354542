import { useEffect, useState } from "react";
import useLocalize from "../../../../services/localize/useLocalize";
import requestPostUserPassword from "../../ajax/requestPostUserPassword";

export type FieldKeys = 'old_password' | 'new_password' | 'new_password_confirm';

export type FieldsState = {
  [key in FieldKeys]: string;
}

const useChangePassword = (onSuccessSave: () => void) => {
  const [isFetching, setIsFetching] = useState(false);
  const localize = useLocalize();

  const [fields, setFields] = useState<FieldsState>({
    old_password: '',
    new_password: '',
    new_password_confirm: '',
  });
  const [fieldsErrorMap, setFieldsErrorMap] = useState<FieldsState>({
    old_password: '',
    new_password: '',
    new_password_confirm: '',
  });
  const { new_password, new_password_confirm, old_password } = fields;

  const hasError = Object.values(fieldsErrorMap).some(i => i.length);
  const submitIsDisabled = !new_password.length
  || !old_password.length
  || !new_password_confirm.length
  || hasError
  || new_password_confirm.length < new_password.length;

  //live validation
  useEffect(() => {
    const oldPasswordTouched = old_password.length;
    const newPasswordConfirmTouched = new_password_confirm.length >= new_password.length;
    const newPasswordsNotEqual = (new_password !== new_password_confirm) && newPasswordConfirmTouched;
    const oldAndNewAreEqual = (old_password === new_password) && oldPasswordTouched;

    setFieldsErrorMap({
      old_password: '',
      new_password: oldAndNewAreEqual ? localize('account.new_password_error') : '',
      new_password_confirm: newPasswordsNotEqual ? localize('account.new_password_confirm_error') : '',
    });
  }, [new_password, new_password_confirm, old_password, localize]);

  const handleFieldChange = (field: string, newValue: string) => {
    setFields({
      ...fields,
      [field]: newValue,
    });
  };

  const handleSaveNewPassword = async () => {
    setIsFetching(true);
    const response = await requestPostUserPassword({ newPassword: new_password, oldPassword: old_password });

    if(response?.errors) {
      const { error_code } = response.errors[0];
      const isOldPasswordError = error_code === 4188;
      const errorText = localize(isOldPasswordError ? 'account.old_password_error' : `error.code.${error_code}`);

      setFieldsErrorMap({
        ...fieldsErrorMap,
        old_password: errorText,
      });
    } else {
      onSuccessSave();
    }
    setIsFetching(false);
  };


  return {
    isFetching,
    fields,
    fieldsErrorMap,
    handleFieldChange,
    handleSaveNewPassword,
    submitIsDisabled,
  };
};

export default useChangePassword;
