import Button from 'src/ui-kit/Button';
import { useTheme } from "src/ui-kit/theme";
import { useLocalize } from "../../../../services/localize/useLocalize";
import { UserPhone } from "../../../../services/userProfile/types";
import IconFont from '../../../../ui-kit/IconFont';
import PhoneInput from "../../../../UIKitOld/PhoneInput/PhoneInput";
import { UserPhonesStyled } from "./UserPhones.styles";

interface Props {
  phones: UserPhone[];
  onAddButtonClick(): void;
  onRemoveButtonClick(phone: string): void;
}

const UserPhones = (props: Props) => {
  const { phones, onAddButtonClick, onRemoveButtonClick } = props;
  const localize = useLocalize();
  const theme = useTheme();

  return (
    <UserPhonesStyled className="UserPhones" theme={theme}>
      <div className="UserPhones__title">{localize('account.my_phones')}</div>
      {
        phones.map(({ phone, is_editable }, index) => (
          <div className="UserPhones__field" key={phone}>
            <div className="UserPhones__input" >
              <PhoneInput
                inputName={`phoneInput-${index}`}
                value={phone}
                onChange={() => { }}
                disabled
                dataMarker={`phoneInput-${index}`}
              />
            </div>
            {
              is_editable && (
                <Button
                  variant="notAccentedGhost"
                  dataMarker={`removePhone-${index}`}
                  width="auto"
                  onClick={() => onRemoveButtonClick(phone)}
                >
                  {localize('general.delete')}
                </Button>
              )
            }
          </div>

        ))
      }
      <Button variant="ghost" width='auto' onClick={onAddButtonClick} dataMarker="add new phone">
        <IconFont name='plus' size='10px' sbs={{ mr: '10px' }} />
        {localize('account.add_phone')}
      </Button>
      <div className="UserPhones__description">
        {localize('account.add_phone_description')}
      </div>
    </UserPhonesStyled>
  );
};

export default UserPhones;
