import { useEffect, useState } from "react";
import { OrderDetailInfoDto } from "src/api/types";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import Button from 'src/ui-kit/Button';
import { getOrderLink } from "src/utils/getOrderLink";
import { getOrderSlotText, getOrderStatusText } from "../../utils/orderTableHelpers";
import OrderItemButton from "../OrderItemButton/OrderItemButton";
import OrdersTableHeader from "../OrdersTableItem/OrdersTableHeader";
import OrdersTableItem from "../OrdersTableItem/OrdersTableItem";

interface Props {
  orders: OrderDetailInfoDto[];
  chainHostname: string;
}

const MAX_VISIBLE_ORDERS = 10;

const OrdersTable = (props: Props) => {
  const { orders, chainHostname } = props;
  const language = useLang();
  const localize = useLocalize();
  const initShowMoreIsVisible = orders.length > MAX_VISIBLE_ORDERS;
  const [showMoreIsVisible, setShowMoreIsVisible] = useState(initShowMoreIsVisible);
  const ordersToShow = showMoreIsVisible ? orders.slice(0, MAX_VISIBLE_ORDERS) : orders;

  useEffect(() => {
    setShowMoreIsVisible(initShowMoreIsVisible);
  }, [initShowMoreIsVisible]);

  return (
    <div className="OrdersTable">
      <OrdersTableHeader />
      {
        ordersToShow.map(order => {
          const {
            id,
            time_window: { date, time_range: timeRange },
            can_be_paid_online: canBePaidOnline,
            price,
            status,
            can_leave_feedback,
            links,
          } = order;

          return (
            <OrdersTableItem
              key={id}
              deliveryTime={getOrderSlotText({ date, timeRange })}
              status={getOrderStatusText({ status, canBePaidOnline, localize })}
              sum={price.total}
              id={id}
              orderLink={getOrderLink({ chainHostname, language, orderId: id })}
              buttonSlot={
                <OrderItemButton
                  orderIsToPay={canBePaidOnline}
                  canLeaveFeedback={can_leave_feedback}
                  links={links}
                />
              }
            />
          );
        })
      }
      {
        showMoreIsVisible && (
          <div className="OrdersTable__showMore" >
            <Button
              variant="secondary"
              onClick={() => setShowMoreIsVisible(false)}
              width='330px'
              dataMarker="Show more"
            >
              {localize('go_to_the_store')}
            </Button>
          </div>
        )
      }
      <style jsx>{`
        .OrdersTable__showMore {
          display: flex;
          justify-content: center;
          margin-top: 40px;
        }
      `}</style>
    </div>
  );
};

export default OrdersTable;
