import { fetcher } from 'src/api/fetch';
import { getErrorsFromResponse } from 'src/utils/api/getErrorsFromResponse';

interface RequestParams {
  business_type: string;
  name: string;
}

const requestPostHorecaRegistration = async (params: RequestParams) => {
  const response = await fetcher(`/user/profile/register_horeca/`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(params),
  });

  return getErrorsFromResponse(response);
};

export default requestPostHorecaRegistration;
