import { phone, tablet } from "src/styles/media";
import typography from "src/styles/typography";

interface Props {
  text: string;
}

const AccountEmptyDataPlaceholder = (props: Props) => {
  return (
    <div className="AccountEmptyDataPlaceholder">
      {props.text}
      <style jsx>{`
        .AccountEmptyDataPlaceholder {
          ${typography.h3}
          text-align: center;
          margin: 78px 0 0;
        }

        @media ${tablet} {
          .AccountEmptyDataPlaceholder {
            margin: 49px 0 0;
          }
        }

        @media ${phone} {
          .AccountEmptyDataPlaceholder {
            margin: 113px 0 0;
          }
        }
      `}</style>
    </div>
  );
};

export default AccountEmptyDataPlaceholder;
