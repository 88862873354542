'use client';
import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import AccountNavigation from 'src/components/AccountNavigation/AccountNavigation';
import { useLocalize } from 'src/services/localize/useLocalize';
import { useTheme } from 'src/ui-kit/theme';
import { desktop } from '../../../../styles/media';
import withAuth from '../withAuth/withAuth';
import { AccountLayoutStyled } from './AccountLayout.styles';
import { usePathname } from 'next/navigation';

interface Props {
  children?: ReactNode;
}

const AccountLayout = (props: Props) => {
  const { children } = props;
  const theme = useTheme();
  const localize = useLocalize();
  const isDesktop = useMediaQuery({ query: desktop });
  const pathname = usePathname() || '';
  const pathParts = pathname.split('/').slice(1, -1);
  const activePageId = pathParts[pathParts.length - 1];
  const menuIsVisible = isDesktop || activePageId === 'account';

  return (
    <AccountLayoutStyled
      theme={theme}
      className="AccountLayout"
    >
      <div className="AccountLayout__container">
        {
          menuIsVisible &&
          <div className="AccountLayout__sidebar" data-maker="Account sidebar">
            <div className='AccountLayout__sidebarTitle'>
              {localize('general.account')}
            </div>
            <AccountNavigation
              place="account"
              activePageId={activePageId}
            />
          </div>
        }

        <div className="AccountLayout__content" data-marker="account-content">
          {children}
        </div>
      </div>
    </AccountLayoutStyled >
  );
};

export default withAuth(AccountLayout);
