import styled from '@emotion/styled';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const FormLabelStyled = styled.label<StyledThemeProps>(({ theme }) => `
  ${typography.h9}
  margin-bottom: 4px;
  display: block;

  &.FormLabel_aligned {
    text-align: start;
  }

  .FormLabel__requiredMark {
    color: ${theme.base.color.error};
  }
`);
