export function formatDateByMask(dateString: string, mask: string, shortYear: boolean = false) {
  const dateObj = new Date(dateString);

  const year = shortYear ? String(dateObj.getFullYear()).slice(-2) : String(dateObj.getFullYear());
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");

  const formattedDate = mask
    .replace("yyyy", year)
    .replace("dd", day)
    .replace("mm", month);

  return formattedDate;
}
