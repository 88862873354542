import { RetailChain } from "src/api/types";
import useLocalize from "src/services/localize/useLocalize";
import FormLabel from "src/UIKitOld/FormLabel/FormLabel";
import SelectStyled from "src/UIKitOld/SelectStyled/SelectStyled";
import { ChainsSelectMenu, ChainsSelectOption } from "./AdditionalComponents";

interface Props {
  options: ChainOption[];
  activeValue: ChainOption;
  onChange(option: ChainOption): void;
}

export interface ChainOption {
  value: string;
  label: string;
  instance: RetailChain;
}

const ChainsSelect = (props: Props) => {
  const { options, activeValue, onChange } = props;
  const localize = useLocalize();

  return (
    <div className="ChainsSelect">
      <FormLabel
        labelText={localize('general.shops')}
        htmlFor='chains_select'
      />
      <SelectStyled
        options={options}
        value={activeValue}
        onChange={onChange}
        dataMarker='Select chain'
        isSearchable={false}
        inputId="chains_select"
        components={{
          Menu: ChainsSelectMenu,
          Option: ChainsSelectOption,
        }}
      />
      <style jsx>{`
        .ChainsSelect {
          max-width: 330px;
          margin-bottom: 24px;
        }
      `}</style>
    </div>
  );
};

export default ChainsSelect;
