import styled from '@emotion/styled';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const HorecaDocumentsStyled = styled.div<StyledThemeProps>(({ theme }) => `
  padding-top: 15px;
  ${typography.text};

  .HorecaDocuments__steps {
    margin-top: 16px;
    list-style-type: none;
  }

  .HorecaDocuments__stepsIcon {
    margin-right: 8px;
    font-size: 16px;
    color: ${theme.base.color.primary};
  }

  .HorecaDocuments__stepsItem {
    line-height: 24px;
    vertical-align: baseline;
  }

  .HorecaDocuments__stepsItem:not(:last-child) {
    margin-bottom: 12px;
  }

  .HorecaDocuments__questionText {
    ${typography.h6};
    margin-top: 32px;
    margin-bottom: 8px;
  }

  .HorecaDocuments__outsideLink {
    ${typography.text};
    font-weight: 600;
    color: ${theme.base.color.primary};
    text-decoration: none;
  }

  .HorecaDocuments__contacts {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  .HorecaDocuments__contactsItem {
    text-decoration: none;
    ${typography.h6};
    line-height: 31px;
  }

  .HorecaDocuments__contactsItem:hover {
    color: ${theme.base.color.primary};
  }
`);
