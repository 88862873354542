'use client';
import { useEffect, useState } from "react";
import { useLocalize } from "../../../../services/localize/useLocalize";
import { UserProfile } from "../../../../services/userProfile/types";
import useUserProfile from "../../../../services/userProfile/useUserProfile";
import { getClientSideUserEmail } from "../../../../services/userProfile/utils/dataConverters";
import { AccountPageTitle } from "../AccountPageTitle/AccountPageTitle";
import AddPhoneModal from "../AddPhoneModal/AddPhoneModal";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import ChangePhoneModal from "../ChangePhoneModal/ChangePhoneModal";
import LoginSettings from "../LoginSettings/LoginSettings";
import PersonalData from "../PersonalData/PersonalData";
import RemovePhoneModal from "../RemovePhoneModal/RemovePhoneModal";
import UserPhones from "../UserPhones/UserPhones";

type AccountModalName = 'changePhone' | 'changePassword' | 'addPhone';

export type UpdateUserProfileFunc = (data: Partial<UserProfile>) => void;

const defaultProfileInfo: UserProfile = {
  name: '',
  email: '',
  surname: '',
};

const AccountSettingsPage = () => {
  const localize = useLocalize();
  const { userProfile, saveUserProfile } = useUserProfile();
  const [profile, setProfile] = useState<UserProfile>(defaultProfileInfo);
  const phone = userProfile?.login.phone || '';
  const phones = userProfile?.phones || [];

  const [openedModalName, setOpenedModalName] = useState<AccountModalName | null>(null);
  const openModal = (modal: AccountModalName) => setOpenedModalName(modal);
  const closeModal = () => setOpenedModalName(null);
  const [removingPhone, setRemovingPhone] = useState<string | null>(null);

  const updateUserProfile: UpdateUserProfileFunc = data => setProfile({ ...profile, ...data });

  useEffect(() => {
    if (userProfile) {
      setProfile({
        name: userProfile.name,
        surname: userProfile.surname,
        email: getClientSideUserEmail(userProfile.emails),
      });
    } else {
      setProfile(defaultProfileInfo);
      //clear the state if the user was logged out
      closeModal();
      setRemovingPhone(null);
    }
  }, [userProfile]);

  return (
    <>
      <AccountPageTitle
        title={localize('nav.settings')}
      />
      <LoginSettings
        phone={phone}
        onChangeLoginClick={() => openModal('changePhone')}
        onChangePasswordClick={() => openModal('changePassword')}
      />
      <UserPhones
        phones={phones}
        onAddButtonClick={() => openModal('addPhone')}
        onRemoveButtonClick={setRemovingPhone}
      />
      <PersonalData
        onChange={updateUserProfile}
        onSave={saveUserProfile}
        profile={profile}
      />
      {
        openedModalName === 'changePhone' &&
        <ChangePhoneModal onClose={closeModal} />
      }

      {
        openedModalName === 'changePassword' &&
        <ChangePasswordModal onClose={closeModal} />
      }

      {
        openedModalName === 'addPhone' &&
        <AddPhoneModal onClose={closeModal} />
      }

      {
        removingPhone &&
        <RemovePhoneModal
          phone={removingPhone}
          onClose={() => setRemovingPhone(null)}
        />
      }
    </>
  );
};

export default AccountSettingsPage;
