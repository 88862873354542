import { ReactNode } from "react";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import CheckboxWithLabel from "src/UIKitOld/CheckboxWithLabel/CheckboxWithLabel";

interface Props {
  id: string;
  checked: boolean;
  children: ReactNode;
  onClick(): void;
}

const NewsLetterItem = (props: Props) => {
  const { id, onClick, children, checked } = props;
  const labelId = `checkbox-${id}`;

  return (
    <StylishBox
      className="NewsLetterItem"
      sbs={{
        mb: '16px',
      }}
    >
      <div className="NewsLetterItem__checkbox">
        <CheckboxWithLabel
          labelId={labelId}
          checked={checked}
          onClick={onClick}
          dataTestId={labelId}
        >
          {children}
        </CheckboxWithLabel>
      </div>
    </StylishBox>
  );
};

export default NewsLetterItem;
