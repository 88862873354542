import { fetcher } from "src/api/fetch";
import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import { UserSubscriptionItem } from "../hooks/useUserMarketingSubscriptions/useUserMarketingSubscriptions";

export const requestUpdateSubscriptions = async (subscriptions: UserSubscriptionItem[]): Promise<UserSubscriptionItem[]> => {
  const response = await fetcher<UserSubscriptionItem[]>(
    `/user/profile/subscriptions/`,
    {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(subscriptions),
    },
  );

  return getDataFromResponse(response, []);
};

export default requestUpdateSubscriptions;
