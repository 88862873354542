
import { fetcher } from 'src/api/fetch';
import { ApiErrorShape } from 'src/api/types';
import { getErrorsFromResponse } from 'src/utils/api/getErrorsFromResponse';

interface RequestResult {
  errors: ApiErrorShape[];
}

const requestDeleteDeliveryPreset = async (presetId: string, chain: string): Promise<RequestResult | null> => {
  const response = await fetcher(`/user/delivery_presets/${presetId}/`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'x-chain': chain,
    },
  });

  return getErrorsFromResponse(response);
};

export default requestDeleteDeliveryPreset;

