import GeneralModal from "src/components/GeneralModal/GeneralModal";
import useLocalize from "src/services/localize/useLocalize";
import Button from "src/ui-kit/Button";
import IconFont from "src/ui-kit/IconFont";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";
import { useTheme } from "src/ui-kit/theme";
import ApartmentExtra from "./ApartmentExtra";
import OfficeExtra from "./OfficeExtra";
import useAddressPlanEditModal from "./useEditDeliveryPlanAddressModal";
import { ServerCartDeliveryPlanType } from "src/services/userDelivery/types";

export interface AddressPlanEditModalProps {
  onClose: () => void;
  delivery: ServerCartDeliveryPlanType;
  chainId: string;
  profileId: string;
}

const AddressPlanEditModal = (props: AddressPlanEditModalProps) => {
  const { onClose } = props;

  const localize = useLocalize();
  const theme = useTheme();

  const {
    addressText,
    deliveryExtraProps,
    extraAddressType,
    handleSubmit,
  } = useAddressPlanEditModal(props);

  return (
    <GeneralModal
      onClose={onClose}
      isOpen
      withCloseButton={true}
    >
      <StylishBox
        elementProps={{
          'data-marker-popup': 'Address Edit',
        }}
        sbs={{
          width: '100%',
          maxWidth: '495px',
          p: {
            general: '40px',
          },
          px: {
            phone: '16px',
          },
          backgroundColor: theme.base.color.white,
        }}
      >
        <Typography variant="h5" align="center">
          {localize('addresses.edit')}
        </Typography>

        <StylishBox
          sbs={{
            mt: '20px',
            mb: '16px',
          }}
        >
          <IconFont name="pin" color="gray400" sbs={{ verticalAlign: 'middle' }} />
          <Typography
            element="span"
            variant="text5"
            sbs={{ ml: '8px', mr: '4px' }}
          >
          {`${localize('delivery.type.courier')}: `}
          </Typography>
          <Typography
            variant="text6"
            element="span"
          >
            {addressText}
          </Typography>
        </StylishBox>

        {extraAddressType === 'apartment' && (
          <ApartmentExtra {...deliveryExtraProps} />
        )}

        {extraAddressType === 'office' && (
          <OfficeExtra {...deliveryExtraProps} />
        )}

        <Button
          width="100%"
          variant='primary'
          type='button'
          onClick={handleSubmit}
          dataMarker='Save'
          sbs={{
            mt: '24px',
          }}
        >
          <span>{localize('general.save-changes')}</span>
        </Button>
      </StylishBox>
    </GeneralModal>
  );
};

export default AddressPlanEditModal;
