
interface IconProps {
  color?: string;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
}

const SuccessIcon = (props: IconProps) => {
  const {
    color = '#3DC538',
    height = 46,
    width = 46,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 46"
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd' d='M23 42C33.4934 42 42 33.4934 42 23C42 12.5066 33.4934 4 23 4C12.5066 4 4 12.5066 4 23C4 33.4934 12.5066 42 23 42Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd' d='M23 45C35.1503 45 45 35.1503 45 23C45 10.8497 35.1503 1 23 1C10.8497 1 1 10.8497 1 23C1 35.1503 10.8497 45 23 45Z'
        strokeWidth='2'
        stroke={color}
      />
      <path
        d='M31.6127 17.8687C32.0032 17.4781 32.6363 17.4781 33.0269 17.8687C33.4174 18.2592 33.4174 18.8924 33.0269 19.2829L21.7194 30.5904C21.1302 31.1796 20.1748 31.1796 19.5856 30.5904L13.6256 24.6304C13.2351 24.2399 13.2351 23.6067 13.6256 23.2162C14.0162 22.8256 14.6493 22.8256 15.0398 23.2162L20.6525 28.8288L31.6127 17.8687Z'
        fill='white'
      />
    </svg>
  );
};

export default SuccessIcon;
