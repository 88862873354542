import { Fragment } from "react";
import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { StylishBoxStyles } from "src/ui-kit/StylishBox/types";
import CheckboxWithLabel from "src/UIKitOld/CheckboxWithLabel/CheckboxWithLabel";
import FormField from "src/UIKitOld/FormField/FormField";
import { maxLengthEnum } from "../../utils/validateExtraData";
import { DeliveryExtraProps } from "./types";

const ApartmentExtra = (props: DeliveryExtraProps) => {
  const { onChange, onPaste, data } = props;
  const localize = useLocalize();

  const elevatorIsChecked = data.elevator.value;
  const errorMessage = localize('error.code.6102');

  const columnSbsStyles: StylishBoxStyles = {
    display: 'flex',
    gap: '16px',
    mb: '16px',
  };

  return (
    <Fragment>
      <StylishBox
        sbs={{
          display: 'flex',
          gap: '16px',
          flexDirection: {
            phone: 'column',
          },
        }}
      >
        <StylishBox sbs={columnSbsStyles}>
          <FormField
            label={localize('address.entrance')}
            id='entrance'
            value={data.entrance.value}
            onChange={(e) => onChange(e.target.value,data.entrance.name)}
            inputProps={{
              dataMarker: 'Entrance',
              required: data.entrance.required,
              error: data.entrance.hasError ? errorMessage : '',
              maxLength: maxLengthEnum.ENTRANCE,
              onPaste: onPaste(data.entrance.name),
            }}
          />
          <FormField
            label={localize('address.apartment-number')}
            id='apartment_number'
            value={data.room.value}
            onChange={(e) => onChange(e.target.value, data.room.name)}
            inputProps={{
              dataMarker: 'Room',
              required: data.room.required,
              error: data.room.hasError ? errorMessage : '',
              maxLength: maxLengthEnum.ROOM,
              onPaste: onPaste(data.room.name),
            }}
          />

        </StylishBox>
        <StylishBox sbs={columnSbsStyles}>
          <FormField
            label={localize('address.floor')}
            id='floor'
            value={data.floor.value}
            onChange={(e) => onChange(e.target.value, data.floor.name)}
            inputProps={{
              dataMarker: 'Floor',
              required: data.floor.required,
              error: data.floor.hasError ? errorMessage : '',
              maxLength: maxLengthEnum.FLOOR,
              onPaste: onPaste(data.floor.name),
            }}
          />
          <FormField
            label={localize('address.code')}
            id='entrance_code'
            value={data.entrance_code.value}
            onChange={(e) => onChange(e.target.value, data.entrance_code.name)}
            inputProps={{
              dataMarker: 'Code',
              onPaste: onPaste(data.entrance_code.name),
            }}
          />
        </StylishBox>
      </StylishBox>
      <CheckboxWithLabel
        labelId='Elevator'
        checked={elevatorIsChecked}
        dataMarkerValue='Elevator'
        onClick={() => onChange(!elevatorIsChecked, data.elevator.name)}
      >
        {localize('address.elevator')}
      </CheckboxWithLabel>
    </Fragment>
  );
};

export default ApartmentExtra;
