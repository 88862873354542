import Link from "next/link";
import useLang from "src/services/language/useLang";
import IconFont from "src/ui-kit/IconFont";
import Stack from "src/ui-kit/Stack/Stack";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";

interface Props {
  title: string;
}

export function AccountPageTitle({ title }: Props) {
  const lang = useLang();

  return (
    <Stack
      className='AccountPageTitle'
      data-marker="account-title"
      alignItems='center'
      sbs={{ mb: '16px' }}
    >
      <StylishBox sbs={{display: {
        general: 'none',
        tabletPhone: 'block',
      }}}>
        <Link href={`/${lang}/account`}>
          <IconFont name='arrow-left' color='dark' size='24px' sbs={{mr: '16px'}} />
        </Link>
      </StylishBox>
      <Typography variant='h4'>{title}</Typography>
    </Stack>
  );
}
