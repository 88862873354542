import { components } from "react-select";

export const ChainsSelectMenu = props => {
  const { children, ...restProps } = props;
  return (
    <components.Menu {...restProps}>
      <div data-marker='Chains List'>
        {children}
      </div>
    </components.Menu>
  );
};

export const ChainsSelectOption = props => {
  const { children, data, ...restProps } = props;
  return (
    <components.Option {...restProps}>
      <div data-marker={data.value}>{children}</div>
    </components.Option>
  );
};
