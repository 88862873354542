import { useState } from "react";
import { RetailChain } from "src/api/types";
import { useUserDeliveryPresets } from "../../hooks/useUserDeliveryPresets/useUserDeliveryPresets";
import { ServerCartDelivery, ServerCartDeliveryPlanType } from "src/services/userDelivery/types";

const useAccountAddressesLogic = (chain: RetailChain, profileId: string) => {
  const { deliveryPresets, deliveryPresetsAreFetching } = useUserDeliveryPresets(chain.id, profileId);
  const [deleteAddressModalData, setAddressModalData] = useState<null | ServerCartDelivery>(null);
  const [editAddressModalData, setEditAddressModalData] = useState<null | ServerCartDeliveryPlanType>(null);

  const deleteAddressClick = (address: ServerCartDelivery) => {
    setAddressModalData(address);
  };

  const editAddressClick = (address: ServerCartDelivery) => {
    if(address.type === 'plan') {
      setEditAddressModalData(address);
    } else {
      throw new Error('We can edit only delivery type "plan"');
    }
  };

  const closeEditModal = () => {
    setEditAddressModalData(null);
  };

  const closeDeleteModal = () => {
    setAddressModalData(null);
  };

  return {
    deliveryPresets,
    deliveryPresetsAreFetching,
    deleteAddressModalData,
    editAddressModalData,
    closeDeleteModal,
    closeEditModal,
    deleteAddressClick,
    editAddressClick,
  };
};

export default useAccountAddressesLogic;
