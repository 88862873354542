import { useProductPageExperiment } from "src/utils/catalog/useProductPageExperiment";
import useProductRetailInfoLists from "../../hooks/useProductRetailInfoLists";
import RetailsInfo, { CommonRetailsInfoProps } from "../RetailsInfo/RetailsInfo";

export type ProductRetailsInfoProps = CommonRetailsInfoProps & { ean: string };

const ProductRetailsInfo = (props: ProductRetailsInfoProps) => {
  const { ean, ...commonProps } = props;
  const productRetailsInfo = useProductRetailInfoLists(ean);
  const { experimentIsActive } = useProductPageExperiment();

  return <RetailsInfo {...commonProps} {...productRetailsInfo} withRowCards={experimentIsActive} />;
};

export default ProductRetailsInfo;
