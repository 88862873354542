'use client';
import { useLocalize } from "src/services/localize/useLocalize";
import useUserProfile from "src/services/userProfile/useUserProfile";
import { AccountPageTitle } from "../AccountPageTitle/AccountPageTitle";
import HorecaDocuments from "./HorecaDocuments";
import HorecaRegistration from "./HorecaRegistration";

const AccountHorecaPage = () => {
  const localize = useLocalize();
  const { userProfile } = useUserProfile();

  const isHorecaUser = userProfile?.is_horeca;

  return (
    <>
      <AccountPageTitle
        title={localize(isHorecaUser ? 'profile_b2b_client' : 'company_registration')}
      />
      {isHorecaUser ? (
        <HorecaDocuments />
      ) : (
        <HorecaRegistration />
      )}
    </>
  );
};

export default AccountHorecaPage;
