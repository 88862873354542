import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";
import { Typography } from "src/ui-kit/Typography";
import getDataStatus from "src/utils/getDataStatus";

interface TabItem {
  label: string;
  dataMarker?: string;
}

interface TabsProps {
  tabs: TabItem[];
  selectedIndex: number;
  onSelect: (index: number) => void;
  fullWidth?: boolean;
  disabled?: boolean;
}

const Tabs = (props: TabsProps) => {
  const theme = useTheme();

  const { tabs, selectedIndex, onSelect, fullWidth, disabled } = props;

  return (
    <StylishBox
      className='Tabs'
      sbs={{
        display: 'flex',
        flexDirection: fullWidth ? 'column' : 'row',
      }}
    >
      {tabs.map((tab, index) => {
        const isActive = selectedIndex === index;

        return (
          <StylishBox
            className='Tabs__item'
            key={index}
            elementProps={{
              onClick: () => onSelect(index),
            }}
            data-status={getDataStatus(isActive)}
            sbs={{
              py: '8px',
              px: '16px',
              borderBottom: '2px solid',
              borderColor: isActive ? theme.base.color.primary : theme.base.color.gray200,
              width: fullWidth ? '100%' : `${100 / tabs.length}%`,
              textAlign: 'center',
              cursor: disabled ? 'unset' : 'pointer',
            }}
            dataMarker={tab.dataMarker}
          >
            <Typography
              color={isActive ? 'primary' : 'gray400'}
              variant="text5"
              align="center"
            >
              {tab.label}
            </Typography>
          </StylishBox>
        );
      })}
    </StylishBox>
  );
};

export default Tabs;
