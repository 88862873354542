
import { Fragment } from 'react';
import { Typography } from 'src/ui-kit/Typography';

interface Props {
  addressText: string;
  detailsText: string;
}

export default function AccountAddressesInfo(props: Props) {
  const { detailsText, addressText } = props;

  return (
    <Fragment>
      <Typography variant="text6">{addressText}</Typography>
      {
        detailsText && (
          <Typography sbs={{ mt: '4px' }} color="gray600" variant="text6">
            {detailsText}
          </Typography>
        )
      }
    </Fragment>
  );
}
