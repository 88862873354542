import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import typography from '../../styles/typography';

export const NotificationBarStyled = styled.p<StyledThemeProps>(({ theme }) => `
    background-color: ${theme.base.color.warning};
    ${typography.text};
    line-height: 24px;
    padding: 12px 16px;
    margin: 0;
    display: flex;
    align-items: center;
    text-align: left;
  }

  strong {
    font-weight: 600;
  }
`);

