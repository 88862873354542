import useLocalize from "src/services/localize/useLocalize";
import Button from "src/ui-kit/Button";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";
import AddressPresetList from "../AddressPresetList/AddressPresetList";
import useSavedAddressesTab from "./useSavedAddressesTab";

const SavedAddressesTab = () => {
  const localize = useLocalize();

  const {
    submitButtonIsDisabled,
    addressPresetListProps,
  } = useSavedAddressesTab();

  const presetsIsEmpty = addressPresetListProps.presets.length === 0;

  return (
    <StylishBox
      className='SavedAddressesTab'
      sbs={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {presetsIsEmpty && (
        <Typography variant='text5' align='center'>
          {localize('address.saved-address-empty')}
        </Typography>
      )}

      <AddressPresetList {...addressPresetListProps} />

      <StylishBox
        sbs={{
          mt: 'auto',
        }}
      >
        <Button
          variant='primary'
          disabled={submitButtonIsDisabled}
          type='submit'
          dataMarker='Confirm address'
        >
          {localize('select_address')}
        </Button>
      </StylishBox>
    </StylishBox>
  );
};

export default SavedAddressesTab;
