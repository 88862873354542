import { OrderStatus } from "src/api/types";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import { formatDateByMask } from "src/utils/date/formatDateByMask";

interface SlotParams {
  date: string;
  timeRange: string;
}

export const getOrderSlotText = ({ date, timeRange }: SlotParams) => {
  const dateText = formatDateByMask(date, 'dd.mm.yyyy', true);
  const spaces = '\u00A0 \u00A0';
  return `${dateText}${spaces}${timeRange}`;
};

interface OrderStatusParams {
  canBePaidOnline: boolean;
  status: OrderStatus;
  localize: LocalizeFunc;
}

export const getOrderStatusText = (params: OrderStatusParams) => {
  const { canBePaidOnline, status, localize } = params;
  return canBePaidOnline
    ? localize('order_status_wait_for_pay')
    : localize(`order_status_${status}`);
};
