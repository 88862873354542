'use client';
import LoadingBarIndicator from "src/components/LoadingBarIndicator/LoadingBarIndicator";
import useLocalize from "src/services/localize/useLocalize";
import useAccountPagePreparer from "../../hooks/useAccountPagePreparer/useAccountPagePreparer";
import { AccountPageTitle } from "../AccountPageTitle/AccountPageTitle";
import OrdersPageContent from "../OrdersPageContent/OrdersPageContent";

const OrdersPage = () => {
  const localize = useLocalize();
  const { chains, lastVisitedChainId, dataAreReady, profileId } = useAccountPagePreparer();

  return (
    <>
      <AccountPageTitle
        title={localize('nav.orders')}
      />
      {
        dataAreReady
          ? <OrdersPageContent chains={chains} lastVisitChainId={lastVisitedChainId} profileId={profileId} />
          : <LoadingBarIndicator />
      }
    </>
  );
};

export default OrdersPage;
