import { fetcher } from 'src/api/fetch';
import { getErrorsFromResponse } from 'src/utils/api/getErrorsFromResponse';

interface RequestParams {
  phone: string;
  otp: string;
}

const requestPostUserPhoneConfirm = async (params: RequestParams) => {
  const { phone, otp } = params;
  const bodyData = JSON.stringify({ phone, otp });

  const response = await fetcher(`/user/profile/phones/confirm/`, {
    method: 'POST',
    credentials: 'include',
    body: bodyData,
  });

  return getErrorsFromResponse(response);
};

export default requestPostUserPhoneConfirm;
