import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import { phone } from '../../../../styles/media';
import typography from '../../../../styles/typography';

export const UserPhonesStyled = styled.div<StyledThemeProps>(({ theme }) => `
  max-width: 450px;
  margin-bottom: 40px;

  .UserPhones__title {
    ${typography.h4}
    margin-bottom: 16px;
  }
  .UserPhones__buttonIcon {
    margin-right: 10px;
    font-size: 10px;
  }
  .UserPhones__field {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }
  .UserPhones__input {
    width: 330px;
    margin-right: 16px;
    flex-shrink: 0;
  }
  .UserPhones__description {
    max-width: 330px;
    margin-top: 5px;
    ${typography.h9}
    color: ${theme.base.color.gray600};
  }

  @media ${phone} {
    max-width: 100%;

    .UserPhones__field {
      display: block;
      margin-bottom: 16px;
    }
    .UserPhones__input {
      margin-bottom: 4px;
      margin-right: 0;
      width: 100%;
    }
  }
`);
