import Button from 'src/ui-kit/Button';
import { useTheme } from 'src/ui-kit/theme';
import SuccessIcon from '../SuccessIcon/SuccessIcon';
import { BlockSuccessStyled } from './BlockSuccess.styles';

export interface Props {
  title: string;
  description: string;
  buttonText: string;
  onClick(): void;
}

export default function BlockSuccess(props: Props) {
  const { title, description, onClick, buttonText } = props;
  const theme = useTheme();

  return (
    <BlockSuccessStyled className='BlockSuccess'>
      <SuccessIcon
        color={theme.base.color.primary}
        width={44}
        height={44}
      />
      <div className='BlockSuccess__title'>
        {title}
      </div>
      <div className='BlockSuccess__description'>
        {description}
      </div>
      <div className="BlockSuccess__button">
        <Button
          variant='primary'
          dataMarker='success button'
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </div>
    </BlockSuccessStyled>
  );
}
