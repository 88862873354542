import styled from '@emotion/styled';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const LoginPasswordFormStyled = styled.div<StyledThemeProps>(({ theme }) => `
  .LoginPasswordForm__field {
    margin-bottom: 12px;
    position: relative;
  }
  .LoginPasswordForm__button {
    margin-top: 32px;
  }
  .LoginPasswordForm__commonError {
    text-align: center;
  }
  .LoginPasswordForm__commonError + .LoginPasswordForm__button,
  .LoginPasswordForm__additionalWarning + .LoginPasswordForm__button {
    margin-top: 12px;
  }
  .LoginPasswordForm__forgotButton {
    position: absolute;
    z-index: 1;
    right: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    ${typography.h9};
    color: ${theme.button.ghost.color}
  }
  .LoginPasswordForm__forgotButton:hover {
    color: ${theme.button.ghost.colorHover}
  }
  .LoginPasswordForm__forgotButton:active {
    color: ${theme.button.ghost.colorActive};
  }
`);
