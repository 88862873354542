import cn from 'classnames';
import { createContext, Fragment, useContext, useRef } from 'react';
import { Props as DefaultProps, FocusEventHandler, NamedProps, OptionTypeBase, components as reactSelectComponents } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { StylesConfig } from 'react-select/src/styles';
import typography from 'src/styles/typography';
import { useTheme } from 'src/ui-kit/theme';
import { SelectStyled as Select } from './SelectStyled.styled';

export interface SelectStyledCustomProps {
  components?: Partial<SelectComponents<OptionTypeBase>>;
  noOptionsMessage?: NamedProps['noOptionsMessage'];
  isLoading?: boolean;
  hasError?: boolean;
  dataMarker?: string;
  styles?: StylesConfig;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  singleValueFocusedColor?: string;
}

const selectStyledContext = createContext(null);

const SelectStyledControl = (props) => {
  // @ts-expect-error todo: please fix errors when changing this file
  // why is this solution used?
  const { dataMarker } = useContext(selectStyledContext);
  return (
    <reactSelectComponents.Control
      {...props}
      innerProps={{
        ...props.innerProps,
        'data-marker': dataMarker,
      }}
    />
  );
};

const SelectStyled = (props: DefaultProps & SelectStyledCustomProps) => {
  const {
    components = {},
    noOptionsMessage,
    isLoading,
    hasError,
    styles: externalStyles = {},
    singleValueFocusedColor,
    dataMarker,
    onFocus,
    onBlur,
    ...restProps
  } = props;

  const theme = useTheme();

  const isFocused = useRef({ address: false });

  const contextValue = {
    dataMarker,
  };

  const handleFocus: FocusEventHandler = (e) => {
    if (singleValueFocusedColor) {
      isFocused.current.address = true;
    }

    if (typeof onFocus === 'function') {
      onFocus(e);
    }
  };

  const handleBlur: FocusEventHandler = (e) => {
    if (singleValueFocusedColor) {
      isFocused.current.address = false;
    }

    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };

  return (
    <Fragment>
      <selectStyledContext.Provider
        // @ts-expect-error please fix errors when changing this file
        value={contextValue}
      >
        <Select
          uiTheme={theme}
          blurInputOnSelect
          isLoading={isLoading}
          className={cn('SelectStyled', { SelectStyled_hasError: hasError })}
          classNamePrefix='SelectStyled'
          onFocus={handleFocus}
          onBlur={handleBlur}
          components={{
            IndicatorSeparator: null,
            Control: SelectStyledControl,
            ...components,
          }}
          noOptionsMessage={noOptionsMessage}
          styles={{
            input: (styles) => ({
              ...styles,
              ...typography.h5,
            }),
            singleValue: (styles) => ({
              ...styles,
              color: isFocused.current.address ? singleValueFocusedColor : styles.color,
            }),
            option: (styles) => ({
              ...styles,
              padding: '8px 20px',
            }),
            valueContainer: (styles) => ({
              ...styles,
              padding: 0,
              margin: '0 2px 0 20px',
            }),
            ...externalStyles,
          }}
          {...restProps}
        />
      </selectStyledContext.Provider>
    </Fragment>
  );
};

export default SelectStyled;
