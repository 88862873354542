import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import Logo from 'src/components/Logo/Logo';
import Responsive from 'src/components/Responsive';
import { WITH_UBERCATALOG } from 'src/data/constants';
import { Page } from 'src/modules/ubercatalog';
import useAuth from 'src/services/auth/useAuth';
import useLang from 'src/services/language/useLang';
import Stack from 'src/ui-kit/Stack/Stack';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { useTheme } from 'src/ui-kit/theme';
import HeaderLogin from '../HeaderLogin/HeaderLogin';
import Menu from '../Menu/Menu';
import SidebarMenu from '../SidebarMenu/SidebarMenu';
import DesktopSwitchLocale from '../SwitchLocale/DesktopSwitchLocale';
import ZsuButton from '../ZsuButton/ZsuButton';

export const HEADER_HEIGHT = 64;
export const MARGIN_UNDER_HEADER = 12;

const HeaderDesktopSearchBox = dynamic(
  () => import('src/modules/header/components/HeaderSearch/HeaderDesktopSearchBox'),
  { ssr: false },
);

const HeaderMobileSearchBox = dynamic(
  () => import('src/modules/header/components/HeaderSearch/HeaderMobileSearchBox'),
  { ssr: false },
);

const CategoriesMenuButton = dynamic(
  () => import('src/modules/categoriesMenu'),
  { ssr: true },
);

interface Props {
  withSearch?: boolean;
  pageForAnalytics?: Page;
}

const separator24 = <StylishBox sbs={{ mr: '24px' }} />;
const separator16 = <StylishBox sbs={{ mr: '16px' }} />;

const Header = (props: Props) => {
  const { withSearch = false, pageForAnalytics } = props;
  const { isLoggedIn } = useAuth();
  const theme = useTheme();
  const lang = useLang();

  return (
    <StylishBox
      className='Header'
      dataMarker='header'
      elementProps={{
        id: 'header',
      }}
      sbs={{
        height: {
          general: `${HEADER_HEIGHT}px`,
          phone: '57px',
        },
        backgroundColor: theme.base.color.white,
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0',
        left: '0',
        right: '0',
        zIndex: theme.base.zIndex.header,
        borderBottom: `${theme.base.border.v1} ${theme.base.color.gray200}`,
      }}
    >
      <Stack
        sbs={{
          maxWidth: '1405px',
          px: {
            general: '32px',
            phone: '16px',
          },
        }}
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack
          alignItems='center'
        >
          <Stack
            alignItems='center'
            sbs={{
              flexGrow: {
                general: '0',
                phone: '1',
              },
              flexShrink: '0',
            }}
          >
            <SidebarMenu />
            {separator16}
            <Logo language={lang} />
            {separator16}
          </Stack>


          {
            /**
             * @todo: remove this toggle after release of ubercatalog
             */
            WITH_UBERCATALOG && (
              <Fragment>
                <CategoriesMenuButton pageForAnalytics={pageForAnalytics} />
                {separator16}
              </Fragment>
            )
          }


          {withSearch && (
            <Responsive displayOn='desktop'>
              <StylishBox
                sbs={{
                  maxWidth: '100%',
                  width: {
                    desktop: '615px',
                  },
                }}
              >
                <HeaderDesktopSearchBox />
              </StylishBox>
            </Responsive>
          )}
        </Stack>

        <Stack
          alignItems='center'
          justifyContent='flex-end'
          sbs={{ flexGrow: { phone: '0' } }}
        >
          <Responsive displayOn="desktop" >
            <Stack
              alignItems='center'
            >
              {withSearch && separator24}
              <ZsuButton />
              {separator16}
              <DesktopSwitchLocale />
              {separator24}
            </Stack>
          </Responsive>

          {
            !withSearch
              ? (
                <Responsive displayOn='desktop'>
                  <Stack alignItems='center'>
                    <Menu />
                    {separator24}
                  </Stack>
                </Responsive>
              )
              : (
                <Responsive displayOn='phoneTablet'>
                  <HeaderMobileSearchBox />
                </Responsive>
              )
          }

          <HeaderLogin
            isLoggedIn={isLoggedIn}
          />
        </Stack>
      </Stack>
    </StylishBox>
  );
};

export default Header;
