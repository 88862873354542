import { ReactNode } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import IconFont from '../../ui-kit/IconFont';
import { NotificationBarStyled } from './NotificationBar.styles';

interface Props {
  dataMarker?: string;
  children: ReactNode;
}

function NotificationBar(props: Props) {
  const { children, dataMarker } = props;
  const theme = useTheme();

  return (
    <NotificationBarStyled
      theme={theme}
      className='NotificationBar'
      data-marker={dataMarker}
      role='alert'
    >
      <IconFont name='info' size='13px' color='dark' sbs={{ mr: '8px' }} />
      {children}
    </NotificationBarStyled>
  );
}

export default NotificationBar;
