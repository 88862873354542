import { UberProductChain } from "src/api/getProductDetailsData";

export const getEansForDifferentChains = (chains: UberProductChain[]): string[] => {
  // filter out null values and remove duplicates
  return Array.from(
    new Set(
      chains
        .map(i => i.ean)
        .filter((ean): ean is string => ean !== null),
    ),
  );
};
