import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import { ServerCartDeliveryPlan } from "src/services/userDelivery/types";
import { AddressInfo } from "../types";

type ExtraInfoPropName = Extract<
  keyof ServerCartDeliveryPlan,
  "room" | "entrance" | "entrance_code" | "floor" | "block" | "company_name"
>;

interface CodeProp {
  code: string;
  prop: ExtraInfoPropName;
}

const apartmentCodeProps: CodeProp[] = [
  { code: 'address.apartment', prop: 'room' },
  { code: 'address.entrance', prop: 'entrance' },
  { code: 'address.code', prop: 'entrance_code' },
  { code: 'address.floor', prop: 'floor' },
];

const officeCodeProps: CodeProp[] = [
  { code: 'address.block', prop: 'block' },
  { code: 'address.office-number', prop: 'room' },
  { code: 'address.floor', prop: 'floor' },
  { code: 'address.company', prop: 'company_name' },
];

export function getAddressInfoPlan(
  addressData: ServerCartDeliveryPlan,
  localize: LocalizeFunc,
): AddressInfo {
  const { city, street, building } = addressData;

  return {
    address: `${city}, ${street}, ${building}`,
    extra: formatAddressExtra(addressData, localize),
  };
}

export function infoToStr(
  address: ServerCartDeliveryPlan,
  translateId: string,
  propertyName: ExtraInfoPropName,
  localize: LocalizeFunc,
): string {
  const label = localize(translateId).toLowerCase();
  const value = address[propertyName];
  const str = `${label} ${value}`;

  return str;
}

export function formatAddressExtra(
  address: ServerCartDeliveryPlan,
  localize: LocalizeFunc,
) {
  let codeProps: CodeProp[];

  if (address.type === 'apartment') {
    codeProps = apartmentCodeProps;
  } else if (address.type === 'office') {
    codeProps = officeCodeProps;
  } else {
    return '';
  }

  const str = codeProps
    .filter((p) => address[p.prop])
    .map((cp) => infoToStr(address, cp.code, cp.prop, localize))
    .join(', ');

  return str;
}
