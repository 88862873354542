import useLocalize from "src/services/localize/useLocalize";
import { ServerCartDelivery } from "src/services/userDelivery/types";
import { desktop } from "src/styles/media";
import { getAddressInfo } from "../../utils/getAddressInfo";
import { getAddressItemTitle } from "../../utils/getAddressItemTitle";
import AccountAddressItem from "../AccountAddressItem/AccountAddressItem";

export interface AccountAddressesProps {
  addresses: ServerCartDelivery[];
  onDeleteAddressClick: (address: ServerCartDelivery) => void;
  onEditAddressClick: (address: ServerCartDelivery) => void;
}

const AccountAddresses = (props: AccountAddressesProps) => {
  const { addresses, onEditAddressClick, onDeleteAddressClick } = props;
  const localize = useLocalize();

  return (
    <div className="AccountAddresses">
      {
        addresses.map(item => {
          const title = getAddressItemTitle(item.type, localize);
          const { address, extra } = getAddressInfo(item, localize);
          const canBeEdited = item.type === 'plan' && item.address.plan?.type !== 'house';

          return (
            <AccountAddressItem
              key={item.hash}
              title={title}
              addressText={address}
              detailsText={extra}
              canBeEdited={canBeEdited}
              onEditClick={() => onEditAddressClick(item)}
              onDeleteClick={() => onDeleteAddressClick(item)}
            />
          );
        })
      }
      <style jsx>{`
        @media ${desktop} {
          .AccountAddresses {
            max-width: 600px;
          }
        }
      `}</style>
    </div>
  );
};

export default AccountAddresses;
