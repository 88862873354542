import { ApiErrorShape } from "src/api/types";

export const validateEmail = (email: string) =>  {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
};

export const validateEmoji = (text: string) => {
  return /[\u{1F600}-\u{1F6FF}]/gu.test(text);
};

export function validateUserEmail(userEmail: string): ApiErrorShape[] | null {
  const userEmailTrimmed = userEmail.trim();

  if (!(userEmailTrimmed && userEmailTrimmed.length)) {
    return [
      {
        error_code: 6002,
        description: 'Field must not be empty.',
        field: 'email',
      },
    ];
  }


  if (!validateEmail(userEmail)) {
    return [
      {
        error_code: 6001,
        description: 'Enter a valid Email.',
        field: 'email',
      },
    ];
  }

  return null;
}
