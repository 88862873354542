import { OrderLinks } from "src/api/types";
import useLocalize from "src/services/localize/useLocalize";
import Button, { ButtonVariant } from 'src/ui-kit/Button';

interface Props {
  orderIsToPay: boolean;
  canLeaveFeedback: boolean;
  links: OrderLinks;
}

const OrderItemButton = (props: Props) => {
  const { orderIsToPay, links, canLeaveFeedback } = props;
  const localize = useLocalize();

  const defaultProps = {
    variant: 'ghost' as ButtonVariant,
    target: '_blank',
  };

  if (orderIsToPay) {
    return (
      <Button dataMarker="pay" href={links.payment} {...defaultProps}>
        {localize('general.to_pay')}
      </Button>
    );
  }

  if (canLeaveFeedback) {
    return (
      <Button dataMarker="feedback" href={links.feedback} {...defaultProps}>
        {localize('leave_feedback')}
      </Button>
    );
  }

  return null;
};

export default OrderItemButton;
