import { useState } from "react";
import { ApiErrorShape } from "../../../../api/types";
import useUserProfile from "../../../../services/userProfile/useUserProfile";
import { PhoneInputChangeHandler } from "../../../../UIKitOld/PhoneInput/PhoneInput";
import requestPostUserPhone from "../../ajax/requestPostUserPhone";
import requestPostUserPhoneConfirm from "../../ajax/requestPostUserPhoneConfirm";
import { getPurePhone } from "../../utils/getPurePhone";
import { handleResponseWithRetry } from "src/utils/api/handleResponseWithRetry";

const useAddPhone = (onClose: () => void) => {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState('');
  const [formattedPhone, setFormattedPhone] = useState(phone);
  const [retryTimeout, setRetryTimeout] = useState(0);
  const [errors, setErrors] = useState<ApiErrorShape[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { getUserProfile } = useUserProfile();

  const handleChangePhone: PhoneInputChangeHandler = (value, formattedValue) => {
    setErrors([]);
    setPhone(value);
    setFormattedPhone(formattedValue);
  };

  const goToStep = (step: number) => setStep(step);

  const changeStateOnRequestStart = () => {
    setIsFetching(true);
    setRetryTimeout(0);
    setErrors([]);
  };

  const handleFirstStepSubmit = async () => {
    changeStateOnRequestStart();

    const response = await requestPostUserPhone({
      phone: getPurePhone(phone),
    });

    setIsFetching(false);

    handleResponseWithRetry({
      response,
      onError: setErrors,
      onRetry: setRetryTimeout,
      onSuccess: () => goToStep(2),
    });
  };

  const handleSecondStepSubmit = async (otp: string) => {
    changeStateOnRequestStart();
    const response = await requestPostUserPhoneConfirm({ phone, otp });

    setIsFetching(false);

    if (!response?.errors) {
      onClose();
      getUserProfile();
    } else {
      setErrors(response?.errors);
    }
  };

  return {
    goToStep,
    handleChangePhone,
    handleFirstStepSubmit,
    handleSecondStepSubmit,
    phone,
    formattedPhone,
    isFetching,
    step,
    retryTimeout,
    errors,
  };
};

export default useAddPhone;
