import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

const styles = css`
  .NewsLetterList__title {
    margin-bottom: 16px;
    ${typography.h4};
  }
`;

export default styles;
