import styled from '@emotion/styled';
import Select from 'react-select';
import { phone } from 'src/styles/media';
import typography from 'src/styles/typography';
import { Theme } from 'src/ui-kit/theme/types';

export const SelectStyled = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'theme',
})<{ uiTheme: Theme }>(({ uiTheme }) => `
  text-align: left;

  .SelectStyled__control {
    ${typography.h5}
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    outline: 0 none;
    padding: 0;
    border: 1px solid ${uiTheme.base.color.gray300};
    border-radius: ${uiTheme.base.borderRadii.v1};
    transition: 0.2s border-color ease-in-out;
    overflow: hidden;
    text-align: left;
    background-color: ${uiTheme.base.color.white};
    box-shadow: none;
    cursor: text;
  }
  .SelectStyled__placeholder {
    color: ${uiTheme.base.color.gray500};
    margin: 0 2px;
  }
  .SelectStyled__control--is-focused),
  .SelectStyled__control--is-focused:hover {
    border: 1px solid ${uiTheme.base.color.gray500};
  }
  .SelectStyled__control--is-focused .SelectStyled__dropdown-indicator){
    transform: rotate(180deg);
  }
  .SelectStyled__option--is-focused,
  .SelectStyled__option--is-selected {
    ${typography.h5}
    background: ${uiTheme.base.color.gray100};
  }
  .SelectStyled__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .SelectStyled__menu {
    position: absolute;
    margin: 0;
    margin-top: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 10;
    overflow-y: auto;
    border-radius:  ${uiTheme.base.borderRadii.v1};
    border: 1px solid ${uiTheme.base.color.gray200};
    text-align: left;
    max-width: 415px;
  }
  .SelectStyled__menu-list {
    ${typography.h5}
    padding: 0;
  }

  .SelectStyled__menu-list div:active {
    background: ${uiTheme.base.color.gray100};
  }

  .SelectStyled__warning {
    ${typography.h9};
    color: ${uiTheme.base.color.error};
  }

  .SelectStyled_hasError > div:hover,
  .SelectStyled_hasError > div {
    border-color: ${uiTheme.base.color.error};
  }

  @media ${phone} {
    .SelectStyled__menu {
      max-width: 100%;
      max-height: 310px;
      overflow-y: scroll;
    }
  }
`);
