import { useMediaQuery } from "react-responsive";
import useLocalize from "src/services/localize/useLocalize";
import { phone } from "src/styles/media";
import { useTheme } from "src/ui-kit/theme";
import { OrdersTableItemStyled } from "./OrdersTableItem.styles";

const OrdersTableHeader = () => {
  const localize = useLocalize();
  const theme = useTheme();

  const isPhone = useMediaQuery({ query: phone });

  if (isPhone) {
    return null;
  }

  return (
    <OrdersTableItemStyled
      theme={theme}
      className="OrdersTableItem OrdersTableItem_grey"
      data-testid='OrderTableHeader'
    >
      <div className="OrdersTableItem__info">
        <div className="OrdersTableItem__cell OrdersTableItem__cell_id">
          <span className="OrdersTableItem__title">
            {localize('general.number')}
          </span>
        </div>
        <div className="OrdersTableItem__cell OrdersTableItem__cell_delivery">
            <span className="OrdersTableItem__title">
              {localize('general.delivery')}
            </span>
        </div>
        <div className="OrdersTableItem__cell OrdersTableItem__cell_price">
            <span className="OrdersTableItem__title">
              {localize('general.total')}
            </span>
        </div>
        <div className="OrdersTableItem__cell OrdersTableItem__cell_status">
            <span className="OrdersTableItem__title">
              {localize('general.status')}
            </span>
        </div>
      </div>
    </OrdersTableItemStyled>
  );
};

export default OrdersTableHeader;
