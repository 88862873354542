import { fetcher } from 'src/api/fetch';

interface RequestParams {
  phone: string;
}

const requestPostUserPhone = async (params: RequestParams) => {
  const { phone } = params;
  const bodyData = JSON.stringify({ phone });

  const response = await fetcher(`/user/profile/phones/`, {
    method: 'POST',
    credentials: 'include',
    body: bodyData,
  });

  return response;
};

export default requestPostUserPhone;
