import { PlanType, ServerCartDeliveryPlan } from "src/services/userDelivery/types";
import { DeliveryExtraData } from "../components/AddressPlanEditModal/types";

export default function convertExtraDataToDelivery(
  data: DeliveryExtraData,
  type: PlanType,
): Partial<ServerCartDeliveryPlan> {
  return {
    type,
    room: data.room.value,
    floor: data.floor.value,
    block: data.block.value,
    entrance: data.entrance.value,
    company_name: data.company_name.value,
    entrance_code: data.entrance_code.value,
    elevator: data.elevator.value,
    comments: data.comments.value,
  };
}
