import { useState } from 'react';
import BlockSuccess from 'src/components/BlockSuccess/BlockSuccess';
import { useTheme } from 'src/ui-kit/theme';
import { ApiErrorShape } from '../../../../api/types';
import ConfirmPhoneByCode from '../../../../components/ConfirmPhoneByCode/ConfirmPhoneByCode';
import GeneralModal from '../../../../components/GeneralModal/GeneralModal';
import LoginPasswordForm, { ValidationMessage } from '../../../../components/LoginPasswordForm/LoginPasswordForm';
import { useLocalize } from '../../../../services/localize/useLocalize';
import { LocalizeFunc } from '../../../../services/localize/useLocalizeService';
import useChangeUserPhoneLogin from '../../hooks/useChangeUserPhoneLogin';
import { ChangePhoneModalStyled } from './ChangePhoneModal.styles';

export interface ChangePhoneModalProps {
  onClose(): void;
}

const titleKeyMapBySteps = {
  1: 'account.change_phone_title',
  2: 'account.phone_approve',
  3: null,
};

const getValidationMessage = (errors: ApiErrorShape[], localize: LocalizeFunc): ValidationMessage => {
  const { error_code, field } = errors[0];

  if (error_code === 4020 && field === 'captcha_token') {
    return {
      text: localize(`error.code.4024.captcha`),
      field: 'common',
    };
  }

  const textByErrorCode = localize(`error.code.${errors[0].error_code}`);
  return {
    text: textByErrorCode,
    field: field === 'phone' ? 'phone' : 'common',
  };
};

const ChangePhoneModal = (props: ChangePhoneModalProps) => {
  const { onClose } = props;
  const {
    fields,
    retryTimeout,
    step,
    handleFieldChange,
    handleSubmitFirstStep,
    handleSubmitSecondStep,
    goToStep,
    isFetching,
    requestErrors,
  } = useChangeUserPhoneLogin();

  const theme = useTheme();
  const localize = useLocalize();

  const [formattedPhone, setFormattedPhone] = useState<string>(fields.phone);
  const firstStepValidationMessage = requestErrors.length > 0 ? getValidationMessage(requestErrors, localize) : undefined;

  const handleChangePhone = (phone: string, formattedPhone: string) => {
    handleFieldChange('phone', phone);
    setFormattedPhone(formattedPhone);
  };

  const activeTitleKey = titleKeyMapBySteps[step];

  return (
    <GeneralModal
      onClose={onClose}
      isOpen={true}
      withCloseButton={true}
      isFetching={isFetching}
    >
      <ChangePhoneModalStyled
        theme={theme}
        className='ChangePhoneModal'
        data-testid='ChangePhoneModal'
        data-marker-popup='Change the phone for login'
      >
        {
          activeTitleKey && (
            <div className='ChangePhoneModal__title'>
              <span>{localize(activeTitleKey)}</span>
            </div>
          )
        }

        {step === 1 && (
          <LoginPasswordForm
            onPasswordChange={(e) => handleFieldChange('password', e.target.value)}
            onPhoneChange={handleChangePhone}
            phone={fields.phone}
            password={fields.password}
            onSubmit={handleSubmitFirstStep}
            error={firstStepValidationMessage}
            buttonText={localize('general.continue')}
          />
        )}
        {step === 2 && (
          <ConfirmPhoneByCode
            phone={formattedPhone}
            onStepBack={() => goToStep(1)}
            onRepeat={handleSubmitFirstStep}
            onComplete={handleSubmitSecondStep}
            retryAfterSeconds={retryTimeout}
            errors={requestErrors}
          />
        )}
        {step === 3 && (
          <BlockSuccess
            title={localize('account.login_updated_title')}
            description={localize('account.login_updated_description')}
            onClick={onClose}
            buttonText={localize('general.close')}
          />
        )}
      </ChangePhoneModalStyled>
    </GeneralModal>
  );
};

export default ChangePhoneModal;
