import { ChangeEventHandler, KeyboardEventHandler, ReactNode } from "react";
import Button from 'src/ui-kit/Button';
import { useTheme } from "src/ui-kit/theme";
import NotificationBar from "src/UIKitOld/NotificationBar/NotificationBar";
import { useLocalize } from "../../services/localize/useLocalize";
import ErrorMessage from "../../UIKitOld/ErrorMessage/ErrorMessage";
import PasswordInput from "../../UIKitOld/PasswordInput/PasswordInput";
import PhoneInput from "../../UIKitOld/PhoneInput/PhoneInput";
import { LoginPasswordFormStyled } from "./LoginPasswordForm.styles";

export interface ValidationMessage {
  field: 'phone' | 'common' | 'additional_warning';
  text: ReactNode;
}

export interface Props {
  onPhoneChange(value: string, formattedValue: string): void;
  onPasswordChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit(): void;
  phone: string;
  password: string;
  phoneLabel?: string;
  passwordLabel?: string;
  error?: ValidationMessage;
  buttonText: string;
  onForgotClick?: () => void;
}

const LoginPasswordForm = (props: Props) => {
  const localize = useLocalize();
  const { phone, phoneLabel, password, passwordLabel, onPasswordChange, onPhoneChange, onSubmit, error, buttonText, onForgotClick } = props;
  const theme = useTheme();

  const hasPhoneError = error?.field === 'phone';
  const hasCommonError = error?.field === 'common';
  const hasAdditionalWarningError = error?.field === 'additional_warning';

  const submitIsDisabled = !(phone.length > 3 && password.length > 0);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter' && !submitIsDisabled) {
      onSubmit();
    }
  };

  const withForgotButton = typeof onForgotClick === 'function';

  return (
    <LoginPasswordFormStyled
      theme={theme}
      className='LoginPasswordForm'
      onKeyDown={handleKeyDown}
    >
      <div className="LoginPasswordForm__field">
        <PhoneInput
          inputName='phone'
          label={phoneLabel ? phoneLabel : localize('general.mobile')}
          onChange={onPhoneChange}
          value={phone}
          dataMarker='Mobile Phone'
          hasError={hasPhoneError}
          errorText={hasPhoneError ? error.text : ''}
        />
      </div>
      <div className="LoginPasswordForm__field">
        {withForgotButton && (
          <button
            className="LoginPasswordForm__forgotButton"
            onClick={onForgotClick}
            data-marker="Forgot"
          >
            {localize('login.password.forgot-2')}
          </button>
        )}

        <PasswordInput
          label={passwordLabel ? passwordLabel : localize('account.current_password')}
          onChange={onPasswordChange}
          value={password}
        />
      </div>

      {hasAdditionalWarningError && (
        <div className="LoginPasswordForm__additionalWarning">
          <NotificationBar dataMarker="Additional Warning">
            {error.text}
          </NotificationBar>
        </div>
      )}

      {hasCommonError && (
        <div className="LoginPasswordForm__commonError">
          <ErrorMessage dataTestId="common-error">
            {error.text}
          </ErrorMessage>
        </div>
      )}

      <div className="LoginPasswordForm__button">
        <Button
          variant="primary"
          disabled={submitIsDisabled}
          onClick={onSubmit}
          dataMarker='Submit'
        >
          {buttonText}
        </Button>
      </div>
    </LoginPasswordFormStyled>
  );
};

export default LoginPasswordForm;
