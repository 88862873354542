import Button from 'src/ui-kit/Button';
import { useLocalize } from "../../../../services/localize/useLocalize";
import FormLabel from "../../../../UIKitOld/FormLabel/FormLabel";
import Input from "../../../../UIKitOld/Input/Input";
import PhoneInput from "../../../../UIKitOld/PhoneInput/PhoneInput";
import styles from "./LoginSettings.styles";

export interface LoginSettingsProps {
  phone: string;
  onChangeLoginClick(): void;
  onChangePasswordClick(): void;
}

const LoginSettings = (props: LoginSettingsProps) => {
  const { phone, onChangeLoginClick, onChangePasswordClick } = props;
  const localize = useLocalize();

  return (
    <div className="LoginSettings">
      <div className="LoginSettings__title">{localize('account.login_password')}</div>
      <div className="LoginSettings__field">
        <FormLabel htmlFor="loginPhone" labelText={localize('general.mobile')} />
        <div className="LoginSettings__fieldRow">
          <div className="LoginSettings__input">
            <PhoneInput
              inputName="loginPhone"
              onChange={() => { }}
              disabled
              value={phone}
              dataMarker='Login phone'
            />
          </div>
          <div className="LoginSettings__button">
            <Button
              width='auto'
              variant="ghost"
              onClick={onChangeLoginClick}
              dataMarker="Change login phone"
            >
              {localize('general.change')}
            </Button>
          </div>
        </div>
      </div>
      <div className="LoginSettings__field">
        <FormLabel htmlFor="loginPassword" labelText={localize('general.password')} />
        <div className="LoginSettings__fieldRow">
          <div className="LoginSettings__input">
            <Input
              id="loginPassword"
              name="loginPassword"
              type="password"
              dataMarker="login password"
              value="********"
              disabled
            />
          </div>
          <div className="LoginSettings__button">
            <Button
              width='auto'
              variant="ghost"
              onClick={onChangePasswordClick}
              dataMarker="Change password"
            >
              {localize('general.change')}
            </Button>
          </div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default LoginSettings;
