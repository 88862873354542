import { SubscriptionType } from "src/services/userProfile/types";
import { UserSubscriptionItem } from "./useUserMarketingSubscriptions";

export function allMarketingSubscriptionsAreTrue(arr: UserSubscriptionItem[]) {
  return arr.every(i => i.subscriptions.marketing === true);
}

export function getUpdatedMarketingItem(item: UserSubscriptionItem, value: boolean, key: SubscriptionType): UserSubscriptionItem {
  return {
    ...item,
    subscriptions: {
      ...item.subscriptions,
      [key]: value,
    },
  };
}
