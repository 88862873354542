import { useState } from "react";
import requestDeleteDeliveryPreset from "../../ajax/requestDeleteDeliveryPreset";
import requestPostDeliveryPreset from "../../ajax/requestPostDeliveryPreset";
import { useUserDeliveryPresets } from "../../hooks/useUserDeliveryPresets/useUserDeliveryPresets";
import convertDeliveryToExtraData from "../../utils/convertDeliveryToExtraData";
import convertExtraDataToDelivery from "../../utils/convertExtraDataToDelivery";
import validateEmoji from "../../utils/validateEmoji";
import validateExtraData from "../../utils/validateExtraData";
import { AddressPlanEditModalProps } from "./AddressPlanEditModal";
import { DeliveryExtraData, DeliveryExtraProps, HandleChangeFn, HandlePasteFn } from "./types";
import { ServerCartDeliveryPlanType } from "src/services/userDelivery/types";

const useAddressPlanEditModal = (props: AddressPlanEditModalProps) => {
  const { onClose, delivery, chainId, profileId } = props;
  const { deliveryPresetsMutate } = useUserDeliveryPresets(chainId, profileId);

  const address = delivery.address.plan;
  const extraAddressType = delivery.address.plan.type || 'apartment';

  const [deliveryExtraData, setDeliveryExtraData] = useState<DeliveryExtraData>(
    () => {
      const initialData = convertDeliveryToExtraData(delivery.address.plan);
      return validateExtraData(initialData).validatedData;
    },
  );

  const editDeliveryPreset = async (preset: ServerCartDeliveryPlanType) => {
    const currentPresetId = preset.hash;

    const newPreset = await requestPostDeliveryPreset(preset, chainId);

    // @todo: https://zakaz.atlassian.net/browse/SHWEB-9684
    if (newPreset?.id !== preset.hash) {
      await requestDeleteDeliveryPreset(currentPresetId, chainId);
    }

    // Обновляем кеш: удаляем элемент локально
    deliveryPresetsMutate();
  };

  const handleSubmit = async () => {
    const { hasErrors } = validateExtraData(deliveryExtraData);

    if (hasErrors) {
      return;
    }

    const data = convertExtraDataToDelivery(deliveryExtraData, extraAddressType);

    const newAddressPlanPreset: ServerCartDeliveryPlanType = {
      ...delivery,
      address: {
        plan: {
          ...address,
          ...data,
        },
      },
    };

    await editDeliveryPreset(newAddressPlanPreset);
    onClose();
  };

  const handlePaste: HandlePasteFn = (field) => (e) => {
    const bufferText = e.clipboardData.getData('text');
    const valueIncludesEmoji = validateEmoji(bufferText);

    if (valueIncludesEmoji) {
      handleChange(deliveryExtraData[field].value, field);
    }
  };

  const handleChange: HandleChangeFn = (value, name) => {
    if (name === 'floor' && isNaN(+value)) {
      return;
    }

    const nextData = {
      ...deliveryExtraData,
      [name]: {
        ...deliveryExtraData[name],
        value,
      },
    };

    const { validatedData } = validateExtraData(nextData);

    setDeliveryExtraData(validatedData);
  };

  const deliveryExtraProps: DeliveryExtraProps = {
    data: deliveryExtraData,
    onChange: handleChange,
    onPaste: handlePaste,
  };

  const addressText = `${address.city}, ${address.street}, ${address.building}`;

  return {
    addressText,
    extraAddressType,
    deliveryExtraProps,
    handleSubmit,
  };
};

export default useAddressPlanEditModal;

