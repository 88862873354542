import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import { ServerCartDeliveryPickup } from "src/services/userDelivery/types";
import personalizeString from "src/utils/personalize";
import { AddressInfo } from "../types";

export function getAddressInfoPickup(
  addressData: ServerCartDeliveryPickup,
  localize: LocalizeFunc,
): AddressInfo {
  let address = '';

  const { pickup_zone, store } = addressData;

  if (store.address) {
    const { city, street, building } = store.address;
    address = `${city}, ${street}, ${building}`;
  }

  const pickupZoneInfo = pickup_zone
    ? personalizeString(
      localize('address_info_pickup'),
      [
        store.name,
        localize(`pick_up_from_${pickup_zone}`).toLowerCase(),
      ],
    )
    : '';

  return {
    address,
    extra: pickupZoneInfo,
  };
}
