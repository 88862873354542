import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import { ServerCartDelivery, ServerCartDeliveryPickup, ServerCartDeliveryPlan } from "src/services/userDelivery/types";
import { AddressInfo } from "../types";
import { getAddressInfoPickup } from "./getAddressInfoPickup";
import { getAddressInfoPlan } from "./getAddressInfoPlan";

export function getAddressInfo(
  addressData: ServerCartDelivery,
  localize: LocalizeFunc,
): AddressInfo {
  if (addressData.type === 'plan') {
    return getAddressInfoPlan(addressData.address.plan as ServerCartDeliveryPlan, localize);
  }

  if (addressData.type === 'pickup') {
    return getAddressInfoPickup(addressData.address.pickup as ServerCartDeliveryPickup, localize);
  }

  return {
    address: '',
    extra: '',
  };
}
