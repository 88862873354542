import { fetcher } from 'src/api/fetch';
import { OrderDetailInfoDto } from 'src/api/types';
import { getDataFromResponse } from 'src/utils/api/getDataFromResponse';
import useSWR from 'swr';

const emptyArray: OrderDetailInfoDto[] = [];

const useOrders = (chain: string, cacheKey: string = '') => {
  const args = [chain, 'orders', cacheKey];

  const swrFetcher = async ([chain]: string[]) =>{
    const url = `/user/orders/`;

    const response = await fetcher<OrderDetailInfoDto[]>(url, {
      headers: {
        'x-chain': chain,
      },
      credentials: 'include',
    });
    return getDataFromResponse(response, []);
  };

  const { data, error } = useSWR<OrderDetailInfoDto[]>(args, swrFetcher);

  return {
    orders: data || emptyArray,
    ordersAreFetching: !data && !error,
  };
};

export default useOrders;
