import { useMediaQuery } from "react-responsive";
import useLocalize from "src/services/localize/useLocalize";
import { phone } from "src/styles/media";
import Button from 'src/ui-kit/Button';
import { useTheme } from "src/ui-kit/theme";
import IconFont from '../../../../ui-kit/IconFont';
import AccountAddressesInfo from "../AccountAddressesInfo/AccountAddressesInfo";
import { AccountAddressItemStyled } from "./AccountAddressItem.styles";

export type AccountAddressItemActionType = 'edit' | 'delete';

export interface AccountAddressItemProps {
  title: string;
  canBeEdited: boolean;
  addressText: string;
  detailsText: string;
  onDeleteClick: () => void;
  onEditClick: () => void;
}

const AccountAddressItem = (props: AccountAddressItemProps) => {
  const {
    title,
    canBeEdited,
    addressText,
    detailsText,
    onDeleteClick,
    onEditClick,
  } = props;

  const localize = useLocalize();
  const theme = useTheme();
  const isPhone = useMediaQuery({ query: phone });

  return (
    <AccountAddressItemStyled
      className="AccountAddressItem"
      theme={theme}
      data-marker='Address item'
      data-testid="AccountAddressItem"
    >
      <div className="AccountAddressItem__header">
        <div className="AccountAddressItem__title">{title}</div>
        <div className="AccountAddressItem__buttons">
          {
            canBeEdited && (
              <div className="AccountAddressItem__button AccountAddressItem__button_withMarginRight">
                <Button
                  variant={isPhone ? 'notAccentedGhost' : 'ghost'}
                  dataMarker="edit address"
                  onClick={onEditClick}
                >
                  {isPhone
                    ? <IconFont name='pencil' size='18px' />
                    : localize('general.change')
                  }
                </Button>
              </div>
            )
          }
          <div className="AccountAddressItem__button">
            <Button
              variant="notAccentedGhost"
              dataMarker="remove address"
              onClick={onDeleteClick}
            >
              {isPhone
                ? <IconFont name='delete' size='18px' />
                : localize('general.delete')
              }
            </Button>
          </div>
        </div>
      </div>
      <AccountAddressesInfo
        addressText={addressText}
        detailsText={detailsText}
      />
    </AccountAddressItemStyled>
  );
};

export default AccountAddressItem;
