import { fetcher } from "src/api/fetch";
import { ServerCartDelivery } from "src/services/userDelivery/types";
import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import useSWR from "swr";

export const deliveryPresetsUrl = `/user/delivery_presets`;

export function useUserDeliveryPresets(chain: string, cacheKey: string = '') {
  const args = [chain, 'delivery_presets', cacheKey];

  const swrFetcher = async ([chain]: string[]) =>{

    const res = await fetcher<ServerCartDelivery[]>(deliveryPresetsUrl, {
      headers: {
        'x-chain': chain,
      },
      credentials: 'include',
    });
    return getDataFromResponse(res, []);
  };

  const { data, error, mutate } = useSWR<ServerCartDelivery[]>(args, swrFetcher);

  return {
    deliveryPresets: data || [],
    deliveryPresetsAreFetching: !data && !error,
    deliveryPresetsMutate: mutate,
  };
}
