import { useMemo } from "react";
import { RetailChain } from "src/api/types";
import useLocalize from "src/services/localize/useLocalize";
import { SubscriptionType } from "src/services/userProfile/types";
import { getChainsTranslationMap } from "src/utils/chains/getChainsTranslationMap";
import useUserSubscriptions from "../../hooks/useUserMarketingSubscriptions/useUserMarketingSubscriptions";
import NewsLetterItem from "../NewsLetterItem/NewsLetterItem";
import styles from "./NewsLetterList.styles";

interface Props {
  chains: RetailChain[];
}

const NewsLetterList = (props: Props) => {
  const { chains } = props;
  const {
    zakazSubscriptions,
    chainSubscriptions,
    allChainsIsChecked,
    handleClickZakazCheckbox,
    handleClickChainCheckbox,
    handleClickAllChainsCheckbox,
  } = useUserSubscriptions();

  const localize = useLocalize();
  const chainNameTranslationMap = useMemo(() => getChainsTranslationMap(chains), [chains]);

  const zakazCheckboxesToDisplay: SubscriptionType[] = ['marketing', 'recommendations'];

  return (
    <div className="NewsLetterList">
      {
        zakazCheckboxesToDisplay.map(type => {
          const checkboxId = `zakaz-${type}`;

          return (
            <NewsLetterItem
              key={checkboxId}
              checked={!!zakazSubscriptions?.subscriptions[type]}
              onClick={() => handleClickZakazCheckbox(type)}
              id={checkboxId}
            >
              {localize(`subscriptions.${type}`)}
            </NewsLetterItem>
          );
        })
      }

      <div className="NewsLetterList__title">
        {localize('subscriptions.chain')}
      </div>

      <NewsLetterItem
        checked={allChainsIsChecked}
        onClick={handleClickAllChainsCheckbox}
        id="all"
      >
        {localize('general.all_stores')}
      </NewsLetterItem>

      {
        chainSubscriptions.map(i => (
          <NewsLetterItem
            id={i.chain_id}
            key={`checkbox for ${i.chain_id}`}
            checked={!!i.subscriptions.marketing}
            onClick={() => handleClickChainCheckbox(i)}
          >
            {chainNameTranslationMap[i.chain_id]}
          </NewsLetterItem>
        ))
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default NewsLetterList;
