import { Fragment } from "react";
import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import CheckboxWithLabel from "src/UIKitOld/CheckboxWithLabel/CheckboxWithLabel";
import FormField from "src/UIKitOld/FormField/FormField";
import { maxLengthEnum } from "../../utils/validateExtraData";
import { DeliveryExtraProps } from "./types";

const OfficeExtra = (props: DeliveryExtraProps) => {
  const { onChange, onPaste, data } = props;
  const localize = useLocalize();

  const elevatorIsChecked = data.elevator.value;
  const errorMessage = localize('error.code.6102');

  return (
    <Fragment>
      <StylishBox
        sbs={{
          display: 'flex',
          gap: '16px',
        }}
      >
        <FormField
          label={localize('address.block')}
          id='block'
          value={data.block.value}
          onChange={(e) => onChange(e.target.value, data.block.name)}
          inputProps={{
            dataMarker: 'Corp',
            required: data.block.required,
            error: data.block.hasError ? errorMessage : '',
            maxLength: maxLengthEnum.CORPS,
            onPaste: onPaste(data.block.name),
          }}
        />

        <FormField
          label={localize('address.office-number')}
          id='apartment_number'
          value={data.room.value}
          onChange={(e) => onChange(e.target.value, data.room.name)}
          inputProps={{
            dataMarker: 'Room',
            required: data.room.required,
            error: data.room.hasError ? errorMessage : '',
            maxLength: maxLengthEnum.OFFICE,
            onPaste: onPaste(data.room.name),
          }}
        />
        <FormField
          label={localize('address.floor')}
          id='floor'
          value={data.floor.value}
          onChange={(e) => onChange(e.target.value, data.floor.name)}
          inputProps={{
            dataMarker: 'Floor',
            required: data.entrance.required,
            error: data.floor.hasError ? errorMessage : '',
            maxLength: maxLengthEnum.FLOOR,
            onPaste: onPaste(data.floor.name),
          }}
        />
      </StylishBox>
      <StylishBox sbs={{ my: '16px' }}>
        <FormField
          label={localize('address.company')}
          id='company_name'
          value={data.company_name.value}
          onChange={(e) => onChange(e.target.value, data.company_name.name)}
          inputProps={{
            dataMarker: 'The company name',
            required: data.company_name.required,
            maxLength: maxLengthEnum.COMPANY_NAME,
            onPaste: onPaste(data.company_name.name),
          }}
        />
      </StylishBox>
      <CheckboxWithLabel
        labelId='Elevator'
        checked={elevatorIsChecked}
        dataMarkerValue='Elevator'
        onClick={() => onChange(!elevatorIsChecked, data.elevator.name)}
      >
        {localize('address.elevator')}
      </CheckboxWithLabel>
    </Fragment>
  );
};

export default OfficeExtra;
