import { fetcher } from 'src/api/fetch';
import { getErrorsFromResponse } from 'src/utils/api/getErrorsFromResponse';

interface RequestParams {
  phone: string;
  otp: string;
}

const requestPostConfirmPhoneLogin = async (params: RequestParams) => {
  const { otp, phone } = params;
  const bodyData = JSON.stringify({ phone,otp });

  const response = await fetcher<void>(`/user/profile/phones/confirm_login/`, {
    method: 'POST',
    credentials: 'include',
    body: bodyData,
  });

  return getErrorsFromResponse(response);
};

export default requestPostConfirmPhoneLogin;
