import { useState } from "react";
import { deliveryPresetsMock } from "src/mocks/deliveryPresets";
import { ServerCartDelivery } from "src/services/userDelivery/types";
import { AddressPresetListProps } from "../AddressPresetList/AddressPresetList";

const useSavedAddressesTab = () => {
  const presets = deliveryPresetsMock; // @todos: https://zakaz.atlassian.net/browse/SHWEB-9833;
  const [selectedPresetId, setSelectedPresetId] = useState(deliveryPresetsMock[0].hash);

  const handleSelectPreset = (preset: ServerCartDelivery) => {
    setSelectedPresetId(preset.hash);
  };

  const addressPresetListProps: AddressPresetListProps = {
    onSelectPreset: handleSelectPreset,
    selectedPresetId,
    presets,
  };

  return {
    addressPresetListProps,
    submitButtonIsDisabled: !selectedPresetId,
  };
};

export default useSavedAddressesTab;
