import styled from '@emotion/styled';
import { HEADER_HEIGHT } from 'src/modules/header';
import { phone } from 'src/styles/media';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CatalogFiltersMobileStyled = styled.div<StyledThemeProps>(({ theme }) => `
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  width: 100%;
  z-index: 7;

  &.CatalogFiltersMobile_open {
    z-index: 9;
  }

  .CatalogFiltersMobile__wrap {
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: ${theme.base.color.white};
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
    width: 496px;
  }
  .CatalogFiltersMobile__content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .CatalogFiltersMobile__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
    background: ${theme.base.color.black};
    opacity: 0.3;
    height: 100vh;
    height: 100dvh;
  }

  @media ${phone} {
    top: 57px;

    .CatalogFiltersMobile__wrap {
      width: 100%;
    }
  }
`);
