'use client';
import { ComponentType } from 'react';
import { useModals } from 'src/modules/modals';
import ForAuthorizedUsers from '../ForAuthorizedUsers/ForAuthorizedUsers';


export default function withAuth<P extends object>(WrappedComponent: ComponentType<P>) {
  const Component = (props: P) => {
    const { openModal } = useModals();

    const handleUnauthorized = () => {
      openModal('authorizationModal', { withRedirectToMainPage: true });
    };

    return (
      <ForAuthorizedUsers
        onUnauthorized={handleUnauthorized}
      >
        <WrappedComponent {...props} />
      </ForAuthorizedUsers>
    );
  };

  return Component;
}
