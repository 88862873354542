import { useState } from "react";
import useUserProfile from "../../../../services/userProfile/useUserProfile";
import requestDeletePhone from "../../ajax/requestDeletePhone";

const useRemovePhone = (phone: string, onClose: () => void) => {
  const [isFetching, setIsFetching] = useState(false);
  const { getUserProfile } = useUserProfile();

  const handleRemovePhone = async () => {
    setIsFetching(true);
    const response = await requestDeletePhone(phone);
    setIsFetching(false);

    if (!response?.errors) {
      getUserProfile();
      onClose();
    }
  };

  return {
    isFetching,
    handleRemovePhone,
  };
};


export default useRemovePhone;
