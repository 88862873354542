import { ServerCartDelivery } from "src/services/userDelivery/types";

export const deliveryPresetsMock: ServerCartDelivery[] = [
  {
    "type": "plan",
    "address": {
        "plan": {
            "type": "office",
            "city": "Киев",
            "street": "Тараса Шевченко переулок",
            "building": "8",
            "floor": "344",
            "room": "",
            "company_name": "company_name",
            "block": null,
            "entrance": "",
            "entrance_code": "",
            "elevator": false,
            "comments": null,
            "id": "72df74c45b29b6370056d1c2581fd1b7",
            "coords": {
                "lng": 30.519952726792866,
                "lat": 50.45122935,
            },
            "region_id": null,
            "delivery_service_id": null,
        },
    },
    "hash": "72df74c45b29b6370056d1c2581fd1b7",
  },
  {
    "type": "plan",
    "address": {
        "plan": {
            "type": "house",
            "city": "Киев",
            "street": "Михайловская улица",
            "building": "24в",
            "floor": "",
            "room": "",
            "company_name": null,
            "block": null,
            "entrance": "",
            "entrance_code": "",
            "elevator": false,
            "comments": null,
            "id": "3cc5364c01f9f202e152ba1e924b32ce",
            "coords": {
                "lat": 50.4545635,
                "lng": 30.523087630117818,
            },
            "region_id": null,
            "delivery_service_id": null,
            },
      },
    "hash": "3cc5364c01f9f202e152ba1e924b32ce",
  },
  {
    "type": "plan",
    "address": {
        "plan": {
            "type": "apartment",
            "city": "Дніпро́",
            "street": "Ізмайлівська вулиця",
            "building": "21",
            "floor": "2",
            "room": "2",
            "company_name": null,
            "block": null,
            "entrance": "12",
            "entrance_code": "",
            "elevator": false,
            "comments": "фывфыв",
            "id": "c9b96a9da2d7b31042d6e29c1a09bc14",
            "coords": {
                "lat": 48.4519508,
                "lng": 34.9928728,
            },
            "region_id": null,
            "delivery_service_id": null,
        },
    },
    "hash": "c9b96a9da2d7b31042d6e29c1a09bc14",
  },
  {
    "type": "plan",
    "address": {
        "plan": {
            "type": "house",
            "city": "Львів",
            "street": "вулиця Петра Дорошенка",
            "building": "36",
            "floor": "",
            "room": "",
            "company_name": null,
            "block": null,
            "entrance": "",
            "entrance_code": "",
            "elevator": false,
            "comments": null,
            "id": "e7400088c269d7aa516ecfda5d0faa14",
            "coords": {
                "lat": 49.8383007525245,
                "lng": 24.023532271385196,
            },
            "region_id": null,
            "delivery_service_id": null,
        },
    },
    "hash": "e7400088c269d7aa516ecfda5d0faa14",
    },
  {
      "type": "plan",
      "address": {
          "plan": {
              "type": "house",
              "city": "Київ",
              "street": "вулиця Теодора Драйзера",
              "building": "4",
              "floor": "",
              "room": "",
              "company_name": null,
              "block": null,
              "entrance": "",
              "entrance_code": "",
              "elevator": false,
              "comments": null,
              "id": "ebfa4f5589cffbb1e610cc3ac878e373",
              "coords": {
                  "lat": 50.5019096,
                  "lng": 30.604236,
              },
              "region_id": null,
              "delivery_service_id": null,
          },
      },
      "hash": "ebfa4f5589cffbb1e610cc3ac878e373",
  },
  {
    "type": "pickup",
    "address": {
        "pickup": {
            "store_id": "48215611",
            "pickup_zone": "store",
            "id": "23203bfda1100f2c3127fa82df4f22df",
            "store": {
                "id": "48215611",
                "name": "METRO Теремки DRIVE",
                "retail_chain": "metro",
                "region_id": "kiev",
                "city": "kiev",
                "delivery_service": "zakaz",
                "post_code": "02000",
                "phones": [
                    "0800 202323",
                ],
                "address": {
                    "city": "Киев",
                    "street": "Окружная",
                    "building": "1В",
                    "coords": {
                        "lat": 50.37886,
                        "lng": 30.44221,
                    },
                },
                "email": "metro@zakaz.ua",
                "delivery_types": [
                    "pickup",
                    "plan",
                ],
                "payment_types": {
                    "pickup": [
                        "bank",
                        "card_online",
                        "cod",
                        "card_offline",
                    ],
                    "plan": [
                        "bank",
                        "card_online",
                        "cod",
                    ],
                },
                "payment_types_for_excisable": [
                    "cod",
                ],
                "opening_hours": {
                    "from": "10:00",
                    "to": "19:00",
                },
                "is_active": true,
                "overweight_params": {
                    "pickup": {
                        "weight_step": 10000,
                        "max_step_price": 500,
                        "initial_weight": 40000,
                    },
                    "plan": {
                        "weight_step": 10000,
                        "max_step_price": 2200,
                        "initial_weight": 40900,
                    },
                    "nova_poshta": null,
                    "nova_poshta_fresh": null,
                    "nova_poshta_address": null,
                },
                "pickup_zones": [
                    {
                        "zone": {
                            "ru": "https://img4.zakaz.ua/stores/maps/48215611_store_ru.png",
                            "en": "https://img4.zakaz.ua/stores/maps/48215611_store_en.png",
                            "uk": "https://img4.zakaz.ua/stores/maps/48215611_store_uk.png",
                        },
                        "name": "store",
                    },
                    {
                        "zone": {
                            "ru": "https://img4.zakaz.ua/stores/maps/48215611_parking_ru.png",
                            "en": "https://img4.zakaz.ua/stores/maps/48215611_parking_en.png",
                            "uk": "https://img4.zakaz.ua/stores/maps/48215611_parking_uk.png",
                        },
                        "name": "parking",
                    },
                ],
                "service_fee_percentage": 0,
            },
      },
    },
    "hash": "23203bfda1100f2c3127fa82df4f22df",
  },
  {
    "type": "plan",
    "address": {
        "plan": {
            "type": "house",
            "city": "Київ",
            "street": "Залізничне шосе",
            "building": "2А",
            "floor": "",
            "room": "",
            "company_name": null,
            "block": null,
            "entrance": "",
            "entrance_code": "",
            "elevator": false,
            "comments": null,
            "id": "a2efd2e0154660ba0c1735386e0c3f0d",
            "coords": {
                "lat": 50.4069547,
                "lng": 30.5285791,
            },
            "region_id": null,
            "delivery_service_id": null,
        },
    },
      "hash": "a2efd2e0154660ba0c1735386e0c3f0d",
  },
  {
    "type": "plan",
    "address": {
        "plan": {
            "type": "apartment",
            "city": "Київ",
            "street": "вулиця Гарматна",
            "building": "2",
            "floor": "3",
            "room": "2",
            "company_name": null,
            "block": null,
            "entrance": "1",
            "entrance_code": "",
            "elevator": false,
            "comments": "хоп хей лалалаей",
            "id": "2e849cf690f94253907827993a773a1f",
            "coords": {
                "lat": 50.455268,
                "lng": 30.435489,
            },
            "region_id": null,
            "delivery_service_id": null,
        },
    },
    "hash": "2e849cf690f94253907827993a773a1f",
  },
  {
    "type": "plan",
    "address": {
        "plan": {
            "type": "apartment",
            "city": "Київ",
            "street": "вулиця Кирилівська",
            "building": "101",
            "floor": "1",
            "room": "22",
            "company_name": null,
            "block": null,
            "entrance": "21",
            "entrance_code": "1",
            "elevator": false,
            "comments": null,
            "id": "a3aff135b5efbafae32163cebcd19029",
            "coords": {
                "lat": 50.482408,
                "lng": 30.476304,
            },
            "region_id": null,
            "delivery_service_id": null,
        },
    },
    "hash": "a3aff135b5efbafae32163cebcd19029",
  },
];
