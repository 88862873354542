
import { fetcher } from 'src/api/fetch';
import { ServerCartDelivery } from 'src/services/userDelivery/types';
import { getDataFromResponse } from 'src/utils/api/getDataFromResponse';


type RequestPostDeliveryPresetDto = {
  id: string;
}

const requestDeleteDeliveryPreset = async (preset: ServerCartDelivery, chain: string) => {
  const response = await fetcher<RequestPostDeliveryPresetDto | null>(`/user/delivery_presets/`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'x-chain': chain,
    },
    body: JSON.stringify(preset),
  });

  return getDataFromResponse(response, null);
};

export default requestDeleteDeliveryPreset;

