'use client';
import LoadingBarIndicator from "src/components/LoadingBarIndicator/LoadingBarIndicator";
import useLocalize from "src/services/localize/useLocalize";
import useAccountPagePreparer from "../../hooks/useAccountPagePreparer/useAccountPagePreparer";
import { AccountPageTitle } from "../AccountPageTitle/AccountPageTitle";
import NewsLetterList from "../NewsLetterList/NewsLetterList";

const NewsLetterPage = () => {
  const localize = useLocalize();
  const { dataAreReady, chains } = useAccountPagePreparer();

  return (
    <>
      <AccountPageTitle
        title={localize('nav.newsletter')}
      />
      {
        dataAreReady
          ? <NewsLetterList chains={chains} />
          : <LoadingBarIndicator />
      }
    </>
  );
};

export default NewsLetterPage;
