import { useChains } from "src/api/swr";
import useLang from "src/services/language/useLang";
import useUserProfile from "src/services/userProfile/useUserProfile";

const useAccountPagePreparer = () => {
  const language = useLang();
  const { chains } = useChains({language});
  const { userProfile, isFetching } = useUserProfile();
  //new user not have last visit chain
  const lastVisitedChainId = userProfile?.last_visit || '';
  const dataAreReady = !isFetching && !!userProfile && chains.length > 0;
  const profileId = userProfile?.meta_user_id || '';

  return {
    chains,
    lastVisitedChainId,
    dataAreReady,
    profileId,
  };
};

export default useAccountPagePreparer;
