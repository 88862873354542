import { Delivery } from "src/modules/delivery";
import { fetcher } from "./fetch";
import { Logo, UberCatalogProduct } from "./types";
import { fetchWithMemcached } from "src/utils/ApiCacheClient/fetchWithMemcached";

export interface UberProductChain {
  available: boolean;
  chain_id: string;
  logo: Logo;
  price: number | null;
  store_id: string;
  ean: string | null;
}

export type GetProductDetailsDataResult = {
  product: UberCatalogProduct;
  include: {
    similar: UberCatalogProduct[];
    recommended: UberCatalogProduct[];
  };
  chains: UberProductChain[];
}

interface Params {
  ean: string;
  language: string;
  queryString?: string;
  deliveryType?: Delivery['type'] | null;
  storeIds?: string;
}

export const getProductDetailsData = async (params: Params): Promise<GetProductDetailsDataResult | null> => {
  const {
    ean,
    language,
    queryString = '',
    deliveryType,
    storeIds,
  } = params;

  const url = `/uber_catalog/products/${ean}/${queryString}`;

  const res = await fetchWithMemcached<GetProductDetailsDataResult>({
    url,
    fetchOptions: {
      language,
      headers: {
        ...(deliveryType && { 'x-delivery-type': deliveryType }),
        ...(storeIds && { 'x-store-ids': storeIds }),
      },
    },
    cacheKey: `${url}-${deliveryType}-${storeIds}-${language}`,
  });

  if (res.status === 'fail') {
    console.error('Error fetch for product details');
    return null;
  }

  return res.data;
};

export const getProductDetailsDataWithoutCache = async (params: Params): Promise<GetProductDetailsDataResult | null> => {
  const {
    ean,
    language,
  } = params;

  const url = `/uber_catalog/products/${ean}/`;
  const res = await fetcher<GetProductDetailsDataResult>(url, { language });

  if (res.status === 'fail') {
    console.error('Error fetch for product details');
    return null;
  }

  return res.data;
};
