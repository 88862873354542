import { fetcher } from 'src/api/fetch';
import { ApiErrorShape } from 'src/api/types';
import { getErrorsFromResponse } from 'src/utils/api/getErrorsFromResponse';

interface RequestResult {
  errors: ApiErrorShape[];
}

const requestDeletePhone = async (phone: string): Promise<RequestResult | null> => {
  const response = await fetcher(`/user/profile/phones/${phone}`, {
    method: 'DELETE',
    credentials: 'include',
  });


  return getErrorsFromResponse(response);
};

export default requestDeletePhone;

