import { ChangeEventHandler, ClipboardEventHandler, useState } from "react";
import Button from 'src/ui-kit/Button';
import FormField from "../../../../UIKitOld/FormField/FormField";
import { ApiErrorShape } from "../../../../api/types";
import { useLocalize } from "../../../../services/localize/useLocalize";
import { SaveUserProfileFunc, UserProfile } from "../../../../services/userProfile/types";
import { validateEmoji, validateUserEmail } from "../../../authorizationModal/utils/validate";
import { UpdateUserProfileFunc } from "../AccountSettingsPage/AccountSettingsPage";
import styles from "./PersonalData.styles";

interface PersonalDataProps {
  profile: UserProfile;
  onSave: SaveUserProfileFunc;
  onChange: UpdateUserProfileFunc;
}

type FieldType = keyof UserProfile;

const PersonalData = (props: PersonalDataProps) => {
  const { profile, onSave, onChange } = props;
  const localize = useLocalize();
  const [emailError, setEmailError] = useState<ApiErrorShape>();
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

  const handleSubmit = () => {
    const errors = validateUserEmail(profile.email);

    setButtonIsDisabled(true);

    if (errors) {
      setEmailError(errors[0]);
    } else {
      onSave(profile);
    }
  };

  const handleChangeField = (field: FieldType): ChangeEventHandler<HTMLInputElement> => (e) => {
    const prevValue = profile[field];
    const bufferText = e.target.value;
    const valueIsIncludeEmoji = validateEmoji(bufferText);

    if (valueIsIncludeEmoji) {
      handleSetValue(field, prevValue);
    } else {
      handleSetValue(field, bufferText);
    }
  };

  const handlePaste = (field: FieldType): ClipboardEventHandler<HTMLInputElement> => (e) => {
    const bufferText = e.clipboardData.getData('text');
    const prevValue = profile[field];
    const valueIsIncludeEmoji = validateEmoji(bufferText);

    if (valueIsIncludeEmoji) {
      handleSetValue(field, prevValue);
    }
  };

  const handleSetValue = (field: FieldType, newValue: string) => {
    onChange({ [field]: newValue });
    setButtonIsDisabled(false);

    if (field === 'email' && emailError) {
      setEmailError(undefined);
    }
  };

  return (
    <div className="PersonalData">
      <div className="PersonalData__title">
        {localize('account.personal_data')}
      </div>
      <div className="PersonalData__field">
        <FormField
          id="profile-email"
          value={profile.email || ''}
          onChange={handleChangeField('email')}
          label={localize('correspondence_email')}
          inputProps={{
            error: emailError && localize(`error.code.${emailError.error_code}`),
            dataMarker: 'email input',
            onPaste: handlePaste('email'),
          }}
        />
      </div>
      <div className="PersonalData__field">
        <FormField
          id="profile-name"
          value={profile.name || ''}
          onChange={handleChangeField('name')}
          label={localize('general.first_name')}
          inputProps={{
            dataMarker: 'name input',
            onPaste: handlePaste('name'),
          }}
        />
      </div>
      <div className="PersonalData__field">
        <FormField
          id="profile-surname"
          value={profile.surname || ''}
          onChange={handleChangeField('surname')}
          label={localize('general.last_name')}
          inputProps={{
            dataMarker: 'surname input',
            onPaste: handlePaste('surname'),
          }}
        />
      </div>
      <div className="PersonalData__button">
        <Button
          variant="primary"
          onClick={handleSubmit}
          dataMarker='save personal data'
          disabled={buttonIsDisabled}
        >
          {localize('save_changes')}
        </Button>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default PersonalData;
