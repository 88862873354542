import cn from 'classnames';
import { useTheme } from 'src/ui-kit/theme';
import { FormLabelStyled } from './FormLabel.styles';

export interface FormLabelProps {
  labelText?: string;
  htmlFor: string;
  isAlignedLeft?: boolean;
  withRequiredMark?: boolean;
}

export default function FormLabel(props: FormLabelProps) {
  const { htmlFor, labelText = '', isAlignedLeft, withRequiredMark } = props;

  const theme = useTheme();

  if (!htmlFor) {
    console.error(`htmlFor of label '${labelText}' is empty`);
  }

  return (
    <FormLabelStyled
      theme={theme}
      className={cn('FormLabel', {
        FormLabel_aligned: isAlignedLeft,
      })}
      htmlFor={htmlFor}
    >
      {labelText}
      {withRequiredMark && (
        <span className='FormLabel__requiredMark'> *</span>
      )}
    </FormLabelStyled>
  );
}
