import { useEffect, useState } from 'react';
import { RECAPTCHA_PUBLIC_KEY } from 'src/data/constants';
import useLang from 'src/services/language/useLang';

type Action = 'registration' | 'change_phone' | 'login_recovery';

function toggleBadge(isOn: boolean) {
  const badge = document.querySelector<HTMLElement>('.grecaptcha-badge');

  if (badge) {
    badge.style.opacity = isOn ? '1' : '0';
    badge.style.pointerEvents = isOn ? 'auto' : 'none';
  }
}

export default function useRecaptcha(action: Action) {
  const language = useLang();

  const [recaptchaIsReady, setRecaptchaIsReady] = useState(!RECAPTCHA_PUBLIC_KEY);

  useEffect(() => {
    const recaptchaBadge = document.querySelector<HTMLElement>('.grecaptcha-badge');

    if (recaptchaBadge) {
      setRecaptchaIsReady(true);
      toggleBadge(true);
    }

    if (RECAPTCHA_PUBLIC_KEY && !recaptchaBadge) {
      const scriptEl = document.createElement('script');

      scriptEl.onload = () => {
        window.grecaptcha.ready(() => {
          setRecaptchaIsReady(true);
          toggleBadge(true);
        });
      };
      scriptEl.defer = true;
      scriptEl.async = true;
      scriptEl.type = 'text/javascript';
      scriptEl.id = 'recaptcha-script';
      scriptEl.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_PUBLIC_KEY}&hl=${language}`;

      document.body.appendChild(scriptEl);

      const styleEl = document.createElement('style');
      styleEl.textContent = '.grecaptcha-badge { z-index: 9999; }';

      document.head.appendChild(styleEl);
    }

    return () => {
      toggleBadge(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RECAPTCHA_PUBLIC_KEY, language]);

  const getRecaptchaToken = async () => {
    if (recaptchaIsReady && RECAPTCHA_PUBLIC_KEY) {
      return await window.grecaptcha.execute(RECAPTCHA_PUBLIC_KEY, { action });
    }

    return '';
  };

  return { getRecaptchaToken, recaptchaIsReady };
}
