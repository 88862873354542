'use client';

import LoadingBarIndicator from "src/components/LoadingBarIndicator/LoadingBarIndicator";
import useLocalize from "src/services/localize/useLocalize";
import useAccountPagePreparer from "../../hooks/useAccountPagePreparer/useAccountPagePreparer";
import { AccountPageTitle } from "../AccountPageTitle/AccountPageTitle";
import AddressesPageContent from "../AddressesPageContent/AddressesPageContent";

const AddressesPage = () => {
  const { dataAreReady, chains, lastVisitedChainId, profileId } = useAccountPagePreparer();
  const localize = useLocalize();
  return (
    <>
      <AccountPageTitle title={localize('nav.addresses')} />
      {
        dataAreReady
          ? <AddressesPageContent chains={chains} lastVisitChainId={lastVisitedChainId} profileId={profileId} />
          : <LoadingBarIndicator />
      }
    </>
  );
};

export default AddressesPage;
