import EnterPhoneForm from "src/components/EnterPhoneForm/EnterPhoneForm";
import { useTheme } from "src/ui-kit/theme";
import ConfirmPhoneByCode from "../../../../components/ConfirmPhoneByCode/ConfirmPhoneByCode";
import GeneralModal from "../../../../components/GeneralModal/GeneralModal";
import useLocalize from "../../../../services/localize/useLocalize";
import useAddPhone from "../../hooks/useAddPhone/useAddPhone";
import { AddPhoneModalStyled } from "./AddPhoneModal.styles";

export interface Props {
  onClose(): void;
}

const titleTranslationKeyMap = {
  1: 'account.add_phone_title',
  2: 'account.phone_approve',
};

const AddPhoneModal = (props: Props) => {
  const { onClose } = props;
  const theme = useTheme();
  const localize = useLocalize();

  const {
    handleChangePhone,
    handleFirstStepSubmit,
    handleSecondStepSubmit,
    goToStep,
    phone,
    step,
    formattedPhone,
    retryTimeout,
    isFetching,
    errors,
  } = useAddPhone(onClose);

  return (
    <GeneralModal
      onClose={onClose}
      isOpen={true}
      withCloseButton={true}
      isFetching={isFetching}
    >
      <AddPhoneModalStyled
        theme={theme}
        data-marker-popup='Add phone modal'
        data-testid='AddPhoneModal'
      >
        <div className='AddPhoneModal__title'>
          <span>{localize(titleTranslationKeyMap[step])}</span>
        </div>

        {step === 1 && (
          <EnterPhoneForm
            phone={phone}
            onChange={handleChangePhone}
            onSubmit={handleFirstStepSubmit}
            error={errors[0]}
          />
        )}

        {step === 2 && (
          <ConfirmPhoneByCode
            phone={formattedPhone}
            onStepBack={() => goToStep(1)}
            retryAfterSeconds={retryTimeout}
            errors={errors}
            onRepeat={handleFirstStepSubmit}
            onComplete={handleSecondStepSubmit}
          />
        )}
      </AddPhoneModalStyled>
    </GeneralModal>
  );
};


export default AddPhoneModal;
