import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import { phone } from "../../../../styles/media";
import typography from "../../../../styles/typography";

export const RemovePhoneModalStyled = styled.div<StyledThemeProps>(({ theme }) => `
  width: 410px;
  padding: 40px;
  background-color: ${theme.base.color.white};
  border-radius: ${theme.base.borderRadii.v1};

  .RemovePhoneModal__title {
    text-align: center;
    ${typography.h3}
    margin-bottom: 16px;
  }
  .RemovePhoneModal__description {
    margin-bottom: 20px;
    ${typography.text}
  }
  .RemovePhoneModal__phone {
    font-weight: 600;
  }
  .RemovePhoneModal__buttons {
    display: flex;
  }
  .RemovePhoneModal__separator {
    flex: 0 0 16px;
  }
  @media ${phone} {
    width: 100vw;
    padding: 35px 10px;
    border-radius: 0;
  }
`);

